<footer class="footer-fixed">
    <div>
        <div class="text-center">

            <img class="desk-logo-footer" alt="Logo" src="../../../assets/images/logo_grupo_bancolombia_blanco.svg">
        </div>

        <div class="copyright-2019-gru text-center"> Copyright © {{anio}} Grupo Bancolombia.
        </div>
    </div>
</footer>