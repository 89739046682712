<main>
  <div class="content">
    <app-bc-download-button class="app-bc-download-button" (click)="descargarArchivoLog()"
      matTooltip="Descarga la información del Log">
    </app-bc-download-button>
    <table mat-table [dataSource]="dataSource" matSort>

      <!-- documentType Column -->
      <ng-container matColumnDef="documentType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de<br>documento</th>
        <td mat-cell *matCellDef="let element"> {{element.documentType}} </td>
      </ng-container>

      <!-- documentNumber Column -->
      <ng-container matColumnDef="documentNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> N° de<br>documento</th>
        <td mat-cell *matCellDef="let element" [innerHtml]="element.documentNumber"> </td>
      </ng-container>

      <!-- client Column -->
      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente</th>
        <td mat-cell *matCellDef="let element" [innerHtml]="element.client"> </td>
      </ng-container>

      <!-- businessCode Column -->
      <ng-container matColumnDef="businessCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> C&oacute;digo de<br>negocio</th>
        <td mat-cell *matCellDef="let element" [innerHtml]="">
          <span [innerHtml]="element.businessCode"></span>
          <br>
          <span class="small" [innerHtml]="element.businessName"></span>

        </td>
      </ng-container>



      <!-- queryDate Column -->
      <ng-container matColumnDef="queryDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de <br> consulta</th>
        <td mat-cell *matCellDef="let element">
          <strong>{{element.queryDate | date: 'HH:mm:ss':'-0500'}}</strong>
          <br>
          <span class="small">{{element.queryDate | date: 'dd/MM/yyyy':'-0500'}}</span>

        </td>
      </ng-container>

      <!-- controlList Column -->
      <ng-container matColumnDef="controlList">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Consulta en <br> listas de control</th>
        <td mat-cell *matCellDef="let element">

          <mat-chip-listbox aria-orientation="vertical">
            <mat-chip-option [matTooltip]="element.controlListInfo" matTooltipPosition="right"
              [matTooltipDisabled]="!element.controlListInfo"
              [ngClass]="{'success-text-black': element.controlList == 'EXITOSO',
              'warning-orange': element.controlList == 'ALERTA',
              'danger-text-black': element.controlList == 'BLOQUEO' || element.controlList == 'ERROR' }">
              <i *ngIf="element.controlList == 'EXITOSO'" class="far fa-check-circle"></i>
              <i *ngIf="element.controlList == 'ALERTA'" class="far fa-exclamation-circle"></i>
              <i *ngIf="element.controlList == 'BLOQUEO' || element.controlList == 'ERROR'"
                class="far fa-exclamation-circle"></i>
              <span [innerHtml]="element.controlListText"></span>

            </mat-chip-option>
          </mat-chip-listbox>
        </td>
      </ng-container>

      <!-- mdm Column -->
      <ng-container matColumnDef="mdm">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Master Data <br> Management (MDM)</th>
        <td mat-cell *matCellDef="let element">
          <mat-chip-listbox aria-orientation="vertical">
            <mat-chip-option (click)="eventMdm(element)" [matTooltip]="element.mdmInfo" matTooltipPosition="left"
              [matTooltipDisabled]="!element.mdmInfo"
              [ngClass]="{'white-outerline':element.mdm =='EXISTE_DESACTUALIZADO',
              'blue-text-black': element.mdm == 'NO_EXISTE' || element.mdm == 'EXISTE_ACTUALIZADO',
              'danger-text-black': element.mdm == 'ERROR' }">
              <i *ngIf="element.mdm == 'NO_EXISTE'" class="far fa-address-book"></i>
              <i *ngIf="element.mdm == 'EXISTE_ACTUALIZADO'" class="far fa-address-book"></i>
              <i *ngIf="element.mdm == 'EXISTE_DESACTUALIZADO'" class="far fa-sync"></i>
              <i *ngIf=" element.mdm == 'ERROR'" class="far fa-exclamation-circle"></i>
              <span [innerHtml]="element.mdmText"></span>
            </mat-chip-option>
          </mat-chip-listbox>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <app-custom-paginator [pageSizeOptions]="[5,10,20]" (page)="onPaginateChange($event)"></app-custom-paginator>



  </div>

</main>