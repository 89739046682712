<main class="body2">
  <div id="icon-share">
    <em> <i class="far fa-share-square fa-2x"></i></em>
  </div>

  <h3 class="TituloParam">Exportar log</h3>
  <br>
  <form [formGroup]="downloadLogForm" autocomplete="off">
    <div class="row">
      <div class="col">
        <mat-form-field>
          <mat-label>Fecha Inicial</mat-label>
          <input matInput formControlName="fechaInicial" [matDatepicker]="pickerFechaInicial">
          <mat-datepicker-toggle matSuffix [for]="pickerFechaInicial" required>
            <bc-icon matDatepickerToggleIcon size="xs" aria-hidden="true" aria-label="calendar">
              calendar-check
            </bc-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #pickerFechaInicial></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field>
          <mat-label>Fecha Final</mat-label>
          <input matInput formControlName="fechaFinal" [matDatepicker]="pickerFechaFinal" required>
          <mat-datepicker-toggle matSuffix [for]="pickerFechaFinal">
            <bc-icon matDatepickerToggleIcon size="xs" aria-hidden="true" aria-label="calendar">
              calendar-check
            </bc-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #pickerFechaFinal></mat-datepicker>

        </mat-form-field>
      </div>
    </div>

    <div *ngIf="fechaFinalisInValid()" class="label-error">
      La fecha final debe ser mayor o igual a la inicial
    </div>

    <button type="button" bc-button (click)="descargarLog()" [disabled]="disableButton()"
      [disabled]="disableButton()">DESCARGAR</button>
  </form>
</main>