<body class="body">
    <app-header></app-header>
    <section>
        <article>
            <h1 class="titulo">Cambia la contraseña</h1>
            <h5 class="txt-subtitle-confirm-newpass">Crea una nueva contraseña.</h5>
            <form [formGroup]="newPasswordForm" autocomplete="off">
                <div class="container">
                    <div class="d-flex justify-content-center h-100">
                        <div>
                            <div class="card-login">
                                <div class="card-body">

                                    <div id="set-password-container" class="text-center">
                                        <div>
                                            <bc-icon size="sm" aria-hidden="true" aria-label="prueba">
                                                lock-alt
                                            </bc-icon>
                                            <p class="label-password">Ingresa la contraseña</p>
                                        </div>
                                        <bc-form-field>
                                            <input bc-input typeInput="input" 
                                            name="NewPass" type="password" 
                                            formControlName="NewPass" 
                                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" 
                                            required autofocus />
                                            <span></span>
                                        </bc-form-field>
                                    </div>

                                    <div id="confirm-password-title-container" class="text-center">
                                        <div>
                                            <bc-icon size="sm" aria-hidden="true" aria-label="prueba">
                                                lock-alt
                                            </bc-icon>
                                            <p class="label-password">Confirma la contraseña</p>
                                        </div>
                                    </div>
                                    <div id="input-confirm-password-container" class="group">
                                        <bc-form-field>
                                            <input bc-input typeInput="input" 
                                            name="NewPassOk" type="password"
                                            formControlName="NewPassOk" 
                                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" 
                                            required />
                                            <span></span>
                                        </bc-form-field>
                                    </div>

                                    <div class="captcha-style">
                                        <re-captcha formControlName="captcha"></re-captcha>
                                    </div>
                                </div>
                            </div>

                            <br>
                            <div class="form-group text-center">
                                <button bc-button 
                                type="button"
                                sizeButton="Small" 
                                id="change_password_btn" 
                                [disabled]="newPasswordForm.invalid" 
                                (click)="onSubmit()">CONTINUAR</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </article>
    </section>

    <app-footer></app-footer>
</body>