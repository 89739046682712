<body class="body">
  <div class="margin-top-header">
    <app-header class="header-padding"></app-header>
  </div>
  <section class="margin-top-section">
    <article>
      <h1 class="titulo">¿Olvidaste la contraseña?</h1>
      <h5 class="txt-subtitle-newpass">Crea una nueva.</h5>
      <form [formGroup]="newPasswordForm" autocomplete="off">
        <div class="container">
          <div class="d-flex justify-content-center h-100">
            <div>
              <div class="card-login">
                <div class="card-body">

                  <div id="set-password-container" class="text-center">
                    <div>
                      <bc-icon size="sm" aria-hidden="true" aria-label="prueba">
                        lock-alt
                      </bc-icon>
                      <p class="label-password">Ingresa la contraseña</p>
                    </div>
                    <bc-form-field>
                      <input bc-input typeInput="input" name="NewPass" type="password" formControlName="NewPass"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" required autofocus />
                      <span></span>
                    </bc-form-field>
                  </div>


                  <div id="confirm-password-title-container" class="text-center">
                    <div>
                      <bc-icon size="sm" aria-hidden="true" aria-label="prueba">
                        lock-alt
                      </bc-icon>
                      <p class="label-password">Confirma la contraseña</p>
                    </div>
                  </div>
                  <div id="confirm-password-input-container" class="group">
                    <bc-form-field>
                      <input bc-input typeInput="input" name="NewPassOk" type="password" formControlName="NewPassOk"
                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" required />
                      <span></span>
                    </bc-form-field>
                  </div>
                  <div id="captcha-container" *ngIf="captchaEnable" class="d-flex justify-content-center">
                    <re-captcha formControlName="captcha"></re-captcha>
                  </div>
                </div>
              </div>
              <div class="form-group text-center">
                <re-captcha *ngIf="captchaEnable" #captchaRef="reCaptcha" (resolved)="resolved($event)"
                  size="invisible">
                </re-captcha>
                <button type="button" bc-button sizeButton="Small" id="change_password_btn"
                  [disabled]="newPasswordForm.invalid || !captchaIsValid()" (click)="resetPassword()">CONTINUAR</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </article>
  </section>
  <app-footer></app-footer>
</body>