import { Component } from '@angular/core';
import { BcModalServiceService } from 'src/app/shared/services/commons/bc-modal-service/bc-modal-service.service';

@Component({
  selector: 'app-modal-tiempo-inactividad',
  templateUrl: './modal-tiempo-inactividad.component.html',
  styleUrls: ['./modal-tiempo-inactividad.component.css']
})
export class ModalTiempoInactividadComponent {

  timeWarning:number=0;

  constructor(private bcModalServiceService:BcModalServiceService,) { }

  cerrar(){
    this.bcModalServiceService.close();
    this.reload();
  }

  reload(){
    window.location.reload();
  }

  set data(time:number){
    this.timeWarning=time;
  }
}
