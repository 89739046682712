<div class="head">
  <div class="content-head">
    <div class="row align-items-center">
      <div *ngIf="canFilterbyHeadRelationship">
        <div class="container-mat-mdc-select">

          <mat-form-field>
            <mat-label>Jefatura de relacionamiento</mat-label>
            <input class="mat-select" matInput aria-label="Jefatura de relacionamiento" [matAutocomplete]="auto"
              [formControl]="headRelationshipCtrl">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option class="option-head-relationship" *ngFor="let option of filteredheadRelationship | async"
                [value]="option" (click)="emitHeadrelationshipCtrl()">
                <span>{{option.fullname}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="canSeeBackofficeTab && _currentTab=='0'">
        <div class="container-mat-mdc-select">

          <mat-form-field>
            <mat-label>Filtrar por auxiliar backoffice</mat-label>
            <input class="mat-select" matInput aria-label="Filtrar por auxiliar backoffice" [matAutocomplete]="auto"
              [formControl]="auxiliaryBackofficeCtrl">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option class="option-head-relationship" *ngFor="let option of filteredAuxiliaryBackoffice | async"
                [value]="option" (click)="emitBackofficeCtrl()">
                <span>{{option.fullname}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="!canFilterbyHeadRelationship && !canSeeBackofficeTab">
      </div>
      <div class="filter-text">
        <app-bc-search-input2 outerline=true realTime=false (filter)="filterText($event)" [message]="searchMessages">
        </app-bc-search-input2>
      </div>
    </div>
  </div>
</div>