<div class="div-flex div-notification" *ngIf="enabledNotification">
    <div [ngClass]="{'div-next-todiv div-notification-icon': true,
                    'bg-div-noti-icon-success':type=='success',
                    'bg-div-noti-icon-info':type=='info',
                    'bg-div-noti-icon-error':type=='error',
                    'bg-div-noti-icon-warning':type=='warning'}">
        <bc-icon size="xl" [style.color]="'#2C2A29'" aria-hidden="true" aria-label="icon-noti-success" 
            *ngIf="type=='success'">ok</bc-icon> 
        <bc-icon size="xl" [style.color]="'#2C2A29'" aria-hidden="true" aria-label="icon-noti-info" 
            *ngIf="type=='info'">idea</bc-icon>
        <bc-icon size="xl" [style.color]="'#2C2A29'" aria-hidden="true" aria-label="icon-noti-error" 
            *ngIf="type=='error'">error</bc-icon>
        <bc-icon size="xl" [style.color]="'#2C2A29'" aria-hidden="true" aria-label="icon-noti-warning" 
            *ngIf="type=='warning'">hand</bc-icon>
    </div>
    <div [ngClass]="{ 'div-next-todiv div-notification-msg':true,
                    'bg-dark-msg': isDark,
                    'color-dark-msg': isDark,
                    'color-light-msg': !isDark,
                    'width-div-notification-msg': enabledClose,
                    'width-div-notification-msg-no-close': !enabledClose}">
        <span *ngIf="title!=''" class="txt-notification-title">{{title}}<br><br></span>
        <span class="txt-notification-text">{{text}}</span>            
        <span *ngIf="link!=''"><a href="javascript:void(0);" [ngClass]="{ 'txt-notification-link':true,
            'color-dark-msg': isDark,
            'color-light-msg': !isDark}"
            (click)="actionLink()"><br><br>{{link}}</a></span>
    </div>
    <div class="div-next-todiv div-notification-close" *ngIf="enabledClose">
        <bc-icon
            class="pointer"  
            (click)="disabledNotification()"
            size="sm"  
            name="error" 
            [ariaHidden]="false" 
            ariaLabel="name">
        </bc-icon> 
    </div>
</div>