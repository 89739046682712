import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { BcLoadingService } from '../../services/commons/bc-loading/bc-loading.service';

const LIMIT_LOOP = 3;

@Component({
  selector: 'app-bc-loading',
  templateUrl: './bc-loading.component.html',
  styleUrls: ['./bc-loading.component.css']
})
export class BcLoadingComponent implements OnInit {

  

  showText = 'cargando';
  text: string = 'cargando';
  count: number = 0;
  loading: any;

  @ViewChild('bc_loading', {
    read: ElementRef
}) modalRef: ElementRef;

  public subscription: Subscription;

  constructor(private bcLoadingService: BcLoadingService, private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.subscription = this.bcLoadingService.loading.subscribe(data => {
      if (data.action == "show") {
        this.show(data.text);
      } else if (data.action == "close") {
        this.close();
      }
    });

  }

  show(text: string = "cargando") {
    this.text = text;
    this.showText = this.text;
    this.modalRef.nativeElement.style.display = 'block';
  }

  close() {
    this.modalRef.nativeElement.style.display = 'none';
    clearInterval(this.loading);
  }

  loadingText() {
    this.count = (this.count == LIMIT_LOOP) ? 0 : this.count + 1;
    this.showText = `${this.text}${".".repeat(this.count)}`;
  }


  ngAfterViewChecked() {
    this.loadingText();
    this.cdRef.detectChanges();
  }

}
