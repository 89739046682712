import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from 'src/app/config/config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { ParamService } from '../param/param.service';
import { BcModalServiceService } from '../commons/bc-modal-service/bc-modal-service.service';

import { environment } from 'src/environments/environment';
import { TokenAdmin } from '../../models/TokenAdmin';
import { AlertService } from '../alert/alert.service';
import { ModalActualizarSessionComponent } 
  from '../../components/modal/modal-actualizar-session/modal-actualizar-session.component';
import { ModalTiempoInactividadComponent } 
  from '../../components/modal/modal-tiempo-inactividad/modal-tiempo-inactividad.component';
import { ResponseService } from '../../models/response-service';


const SEGUNDOS_EN_MINUTO = 60;
const MINUTOS_AVISO = 13;
const MINUTOS_CIERRE = 15;
const TIMER = 1000;

@Injectable({
  providedIn: 'root'
})

export class LogOutService {
  private urlEndPoint = URL_SERVICIOS;
  private serviceLogOut = 'closesession';
  private httpHeaders: any;
  private token: string;
  private tokenAdmin: TokenAdmin = new TokenAdmin();
  public timerSubscription: Subscription;
  public inactivityTimeInSeconds = SEGUNDOS_EN_MINUTO * MINUTOS_CIERRE;
  public warningInactivityTimeInSeconds = SEGUNDOS_EN_MINUTO * MINUTOS_AVISO;
  public xhr = new XMLHttpRequest();
  public _navigator: any = navigator;
  public isProduction = environment.production;

  constructor( private http: HttpClient,
               private router: Router,
               public alertService: AlertService,
               public paramService: ParamService,
               private bcModalServiceService: BcModalServiceService, ) { }


  logOutTokenSession() {
    this.logOut().subscribe(
        data => {
                  if ( data.codeReturn === '1') {
                    sessionStorage.removeItem('token');
                    this.paramService.setEmail('');
                    this.router.navigate(['/login']);
                  } else {

                    this.alertService.error(data.message);
                  }

                }, error => {

                  this.alertService.error(error.message);
              });
  }

  logOut() {
    if( this.timerSubscription){
      this.timerSubscription.unsubscribe();
    }
    this.token = this.tokenAdmin.getToken();
    this.httpHeaders = new HttpHeaders({'Content-Type' : 'application/json',
    Authorization: 'Bearer ' + this.token });
    return this.http.get<ResponseService>(`${this.urlEndPoint}/${this.serviceLogOut}`, {headers: this.httpHeaders});
  }

  logOutBackground(){
    if(sessionStorage.getItem("token") && this.isProduction){
      try{
      this.logOutBackgroundXHR();
      }catch(e){
        this.logOutBackgroundBeacon();
      }
    }
  }


  logOutBackgroundXHR() {
    this.xhr.open('GET', `${this.urlEndPoint}/${this.serviceLogOut}`, false);
    this.xhr.setRequestHeader('Content-Type', 'application/json');
    this.xhr.setRequestHeader('Authorization', 'Bearer ' + this.tokenAdmin.getToken());
    this.xhr.send();
  }

  logOutBackgroundBeacon(){
    const token =this.tokenAdmin.getToken();
    this._navigator.sendBeacon(`${this.urlEndPoint}/close/session/background`, token);
  }

  startTimer(){
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription.remove;
    }
    if(this.tokenAdmin.getToken()) {
        this.timerSubscription =  timer(0, TIMER).pipe(
        take(this.inactivityTimeInSeconds)
      ).subscribe(value => {
          if (value == this.warningInactivityTimeInSeconds) {
            this.bcModalServiceService.showWithData(ModalTiempoInactividadComponent,MINUTOS_AVISO);
          }
             },
        err => 
        () => {
          this.logOutBackground();
          sessionStorage.clear();
          this.router.navigate(['/login']);
          this.bcModalServiceService.show(ModalActualizarSessionComponent);
        }
      )
    }
  }

}
