import { Component, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { LogOutService } from './shared/services/logOut/log-out.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnDestroy{


  title = 'Portal Sala de Ventas';
  
  constructor(private logOutService:LogOutService,private _elementRef: ElementRef) {
  }
  ngOnDestroy(): void {
    this.logOutService.logOutBackground();
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    this.logOutService.logOutBackground();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: Event) {
    this.logOutService.logOutBackground();
  }

  ngOnInit(): void {
    this._elementRef.nativeElement.removeAttribute("ng-version");
}

 
} 
     

    

