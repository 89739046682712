import { Component, OnInit } from '@angular/core';
import { ParamService } from 'src/app/shared/services/param/param.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})

export class HomeComponent implements OnInit {

  constructor( public paramService: ParamService, ) {
    
   }

  ngOnInit() {
    //has no implementation
  }


}
