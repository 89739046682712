<body class="body">
    <app-header></app-header>
    <div class="btn_back_container">
        <div (click)="goBack()">
            <bc-icon size="xs" aria-hidden="true" aria-label="Atrás">
                arrow2-left
            </bc-icon>
            <span class="button-back">Atrás</span>
        </div>
    </div>
    <h1 class="display1 hola">¡Hola!</h1>
    <br>
    <form [formGroup]="passwordForm" #captchaProtectedForm="ngForm" autocomplete="off" #formLogin>
        <div class="container">
            <div class="d-flex justify-content-center h-100">
                <div class="content-div" >
                    <h5 class="bienvenido-al-lugar">Somos el lugar que te facilitará el proceso de venta de inmuebles.
                    </h5>
                    <br>
                    <div class="card-login">
                        <div class="card-body">
                            <div class="text-center">
                                <bc-icon size="sm" aria-hidden="true" aria-label="prueba">
                                    lock
                                </bc-icon>
                                <p class="label-password">Ingresa la contraseña</p>
                            </div>
                            <div  class="group top-margin">
                                <bc-form-field>
                                    <input 
                                    (keyup.enter)="enter()" 
                                    bc-input 
                                    typeInput="input" 
                                    name="password" 
                                    type="password" 
                                    formControlName="password" 
                                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" 
                                    required autofocus />
                                    <span></span>
                                </bc-form-field>
                                <br>
                                <div *ngIf="intentos >= MAX_INTENTOS && captchaEnable">
                                    <re-captcha formControlName="captcha"></re-captcha>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="intentos >= MAX_INTENTOS && captchaEnable" class="form-group top-margin-8">
                        <button 
                        type="button"
                        bc-button 
                        [ngClass]="{ 'btn-disabled': disableButton() }"
                        sizeButton="Small" 
                        id="btnContinuar" 
                        name="btnContinuar" 
                        [disabled]="disableButton()" 
                        (click)="login(captchaRef)">CONTINUAR</button>
                    </div>
                    <div *ngIf="intentos < MAX_INTENTOS && captchaEnable" class="form-group top-margin-8" >
                        <re-captcha #captchaRef="reCaptcha" (resolved)="resolved($event)" size="invisible"></re-captcha>
                        <button 
                        type="button"
                        bc-button 
                        [ngClass]="{ 'btn-disabled': disableButton() }"
                        sizeButton="Small" 
                        id="btnContinuar" 
                        name="btnContinuar" 
                        [disabled]="disableButton()" 
                        (click)="login(captchaRef)">CONTINUAR</button>
                        <div class="custom-margin">
                            <a bc-lnk 
                            class="child olvidaste-la-clave"
                            (click)="forgotPassword(captchaRef)">¿Olvidaste la contraseña?</a>
                        </div>
                    </div>
                    <div *ngIf="!captchaEnable" class="form-group top-margin-8">
                        <button
                        type="button"
                        bc-button
                        [ngClass]="{ 'btn-disabled': disableButton() }"
                        sizeButton="Small" 
                        id="btnContinuar" 
                        name="btnContinuar" 
                        [disabled]="disableButton()" 
                        (click)="login('')">CONTINUAR</button>
                        <div class="custom-margin">
                            <a bc-lnk 
                            class="child olvidaste-la-clave"
                            (click)="forgotPasswordRequest()">¿Olvidaste la contraseña?</a>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </form>
    <app-footer></app-footer>
</body>