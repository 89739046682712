<main>
  <p class="txt-document-title" *ngIf="isModal">Documentos Solicitados</p>
  <div class="documentos1" [ngClass]="{'documents-full-size':isFullSize}">
    <p class="txt-document-title">{{encabezados[0]}}</p>

    <div *ngIf="encabezados[4]!== undefined">
      <p class="txt-document-title">{{encabezados[4]}}</p>
      <span class="txt-document-content" *ngIf="mensaje4!==''">{{mensaje4}}</span>
      <span class="txt-document-content" *ngIf="mensaje41!==''">{{mensaje41}}</span>
      <div class="cont-encabezado-actualizacion">
        <span class="txt-document-subtitle">{{encabezados[5]}} &nbsp;</span>
        <span *ngIf="linkEncabezado6 === undefined" class="txt-document-subtitle">{{encabezados[6]}}</span>
        <span *ngIf="linkEncabezado6 !== undefined" class="txt-document-subtitle">
          {{encabezados[6]}}&nbsp;&nbsp;</span>
        <a *ngIf="linkEncabezado6 !== undefined" href={{linkEncabezado6}} target="_ blank" 
          class="txt-document-subtitle-link">Click aquí</a>
      </div>
      <p class="txt-document-subtitle"> {{mensaje5}} </p>

    </div>

    <ul>
      <li *ngFor="let doc1 of DOCUMENTOS_COL1">
        <em id="document-icon" class="bc-icon" *ngIf="doc1.sin_link||doc1.link">
          document
        </em>
        <span class="txt-document-content" *ngIf="doc1.link">{{doc1.Texto}}&nbsp;&nbsp;
          <a class="txt-document-content-link" href={{doc1.vinculo}} target="_ blank"><u>Click aquí</u></a></span>
        <a class="txt-document-content" *ngIf="doc1.sin_link">{{doc1.Texto}}</a>
        <a class="txt-document-content" *ngIf="!doc1.sin_link&&!doc1.link">{{doc1.Texto}}</a>
      </li>
    </ul>
    <div class="div-comp-notification" *ngIf="encabezados[7]">
      <app-notification [text]="encabezados[7]" [type]="'warning'" [enabledClose]=false [isDark]=true>
      </app-notification>
    </div>

    <!--Para las Notas a 1 columna-->
    <div *ngIf="encabezados[3]">
      <p class="txt-document-subtitle">{{encabezados[3]}}</p>
      <p class="txt-document-content">{{mensaje3}}</p>
    </div>

    <p *ngIf="encabezados[2]" class="txt-document-subtitle custom-style">{{encabezados[2]}}</p>

    <ul>
      <li *ngFor="let doc3 of DOCUMENTOS_COL3" class="h11 li1">
        <em id="document-icon" class="bc-icon" *ngIf="doc3.sin_link||doc3.link">
          document
        </em>
        <a class="txt-document-content-link" href={{doc3.vinculo}}><u *ngIf="doc3.link" target="_ blank">
          {{doc3.Texto}}</u></a>
        <a class="txt-document-content" *ngIf="doc3.sin_link">{{doc3.Texto}}</a>
      </li>
      <p class="txt-document-content">{{mensaje}}<a class="txt-document-content">{{mensaje2}}</a></p>
    </ul>

  </div>

  <div *ngIf="encabezados[1] || forceShowDocumentsCol2" class="documentos2">
    <p class="encabezado txt-document-subtitle">{{encabezados[1] ? encabezados[1]: "&nbsp;"}}</p>
    <ul>
      <li *ngFor="let doc2 of DOCUMENTOS_COL2" class="h11 li1">
        <em id="document-icon" class="bc-icon" *ngIf="doc2.sin_link||doc2.link">
          document
        </em>
        <span class="txt-document-content" *ngIf="doc2.link">{{doc2.Texto}}&nbsp;&nbsp;
          <a class="txt-document-content-link" href={{doc2.vinculo}} target="_ blank"><u>Click aquí</u></a></span>
        <a class="txt-document-content" *ngIf="doc2.sin_link">{{doc2.Texto}}</a>
        <a class="txt-document-content" *ngIf="!doc2.sin_link&&!doc2.link">{{doc2.Texto}}</a>
      </li>
    </ul>
  </div>
</main>