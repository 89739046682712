<body class="body">
  <app-header></app-header>
  <h1 *ngIf="!isSendEmail" class="display1 hola">¡Hola!</h1>
  <h1 *ngIf="isSendEmail" class="display1 hola">¡Espera!</h1>
  <br *ngIf="!isSendEmail">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" autocomplete="off">
    <div class="container">
      <div class="d-flex justify-content-center h-100">
        <div id="content-container">
          <h5 *ngIf="!isSendEmail" class="bienvenido-al-lugar">
            Somos el lugar que te facilitará el proceso de venta de inmuebles.
          </h5>

          <br>
          <div class="card-login">

            <div class="card-body">
              <div id="card-body-content" class="group">

                <bc-form-field>
                  <em>user</em>
                  <input bc-input state="default" value=""
                    formControlName="email" class="bc-input"
                    aria-label="Ingrese el usuario"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                    required />
                  <label for="email">Ingrese el usuario</label>
                  <div class="invalid-feedback"><span>El correo electrónico es requerido</span></div>
                </bc-form-field>

              </div>
            </div>
          </div>
          <br>
          <div *ngIf="isSendEmail">
            <h5 class="clave-temporal-enviada">
              Te hemos enviado al correo electrónico tu contraseña temporalde acceso.
            </h5>
          </div>
          <br>
          <div class="form-group">

            <button type="button" bc-button sizeButton="Small" id="login_btn" [disabled]="loginForm.invalid"
              [ngClass]="{ 'btn-disabled': loginForm.invalid }" (click)="onSubmit()">CONTINUAR</button>
            <img *ngIf="loading" alt="Cargando"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/
              C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAA
              AEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAA
              Ah+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB
              4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJh
              mKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpH
              snFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEA
              AAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJC
              gAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJ
              ibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTA
              UkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII
              7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          </div>
        </div>
      </div>
    </div>
  </form>
  <app-footer></app-footer>
</body>
