<main>
  <form [formGroup]="passwordForm" #captchaProtectedForm="ngForm" autocomplete="off">
    <div>
      <bc-icon size="sm" aria-hidden="true" aria-label="prueba">
        lock-alt
      </bc-icon>
      <h3>Cambiar la contraseña</h3>
    </div>

    <div>
      <p class="bc-span-info">Ingresa una nueva contraseña</p>
      <bc-form-field>
        <input bc-input typeInput="input" value="" formControlName="passwordNew" type="password" required autofocus />
        <span></span>
      </bc-form-field>
      <br>

      <p class="bc-span-info">Confirma la contraseña</p>
      <bc-form-field>
        <input bc-input typeInput="input" value="" formControlName="passwordConfirm" type="password" required
          autofocus />
        <span></span>
      </bc-form-field>
      <br>
      <br>
      <button bc-button type="submit" (click)="changePassword()" [disabled]="disableButton()">
        CONTINUAR
      </button>
    </div>

  </form>
</main>