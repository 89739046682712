import {Component, OnInit, ViewChild, ViewContainerRef, Inject, ComponentFactoryResolver, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { BcModalServiceService } from 'src/app/shared/services/commons/bc-modal-service/bc-modal-service.service';

@Component({
  selector: 'app-bc-modal',
  templateUrl: './bc-modal.component.html',
  styleUrls: ['./bc-modal.component.css']
})
export class BcModalComponent implements OnInit {


  @ViewChild('customContent', {
    read: ViewContainerRef
}) viewContainerRef: ViewContainerRef
  
  @ViewChild('modal', {
    read: ElementRef
}) modalRef: ElementRef;

  factoryResolver:any;

  public subscription: Subscription;

  data:any;
  componentView:any;

  isFullSize:boolean = true;

  public showClose: boolean = true;

  constructor(@Inject(ComponentFactoryResolver) factoryResolver, public bcModalServiceService:BcModalServiceService) {
    this.factoryResolver = factoryResolver
  }

  ngOnInit() {
    this.subscription = this.bcModalServiceService.modal.subscribe(data=>{
      this.isFullSize = false;
      this.showClose = data.showClose !== false;
      if(data.action=="show"){
        this.addDynamicComponent(data);
        this.show(data);
      }else if (data.action=="close"){
        this.close();
      }
    });
  }

  close(){
      this.modalRef.nativeElement.style.display = 'none';
  }

  show(data){
    this.isFullSize=(data['isFullSize']);
    this.modalRef.nativeElement.style.display = 'block';
  }

  addDynamicComponent(data:any) {
    const componentFactory = this.factoryResolver.resolveComponentFactory(data.component);
    this.viewContainerRef.clear();
    const dynamicComponent = this.viewContainerRef.createComponent(componentFactory).instance;
    if(data.data != undefined){
      dynamicComponent['data']=data.data;
    }
    
  }

 
}
