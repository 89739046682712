import { DocumentPerson } from './documentPerson';

const LENGTH_TEN = 10;
const LENGTH_SEVEN = 7;
const LENGTH_NINE = 9;
const LENGTH_SIXTEEN = 16;
const LENGTH_ELEVEN = 11;

export class CommonFunctions {
  private personType = {
    NATURAL: "PERSONA NATURAL",
    LEGAL: "PERSONA JURIDICA"
  }

  public patternType = {
    ALPHANUMERIC: "ALPHANUMERIC",
    NUMERIC: "NUMERIC",
    ALPHANUMERIC_ENTIRE: "ALPHANUMERIC_ENTIRE",
    NUMERIC_ENTIRE: "NUMERIC_ENTIRE"
  }

  private documents = {
    CC: new DocumentPerson(
      "CC", "cédula de ciudadania", this.personType.NATURAL, this.patternType.NUMERIC, LENGTH_TEN),
    CE: new DocumentPerson(
      "CE", "cédula de extranjeria", this.personType.NATURAL, this.patternType.NUMERIC, LENGTH_SEVEN),
    NIT: new DocumentPerson(
      "NIT", "NIT", this.personType.LEGAL, this.patternType.NUMERIC_ENTIRE, LENGTH_NINE ),
    PAS: new DocumentPerson(
      "PAS", "pasaporte", this.personType.NATURAL, this.patternType.ALPHANUMERIC, LENGTH_SIXTEEN),
    IEPN: new DocumentPerson(
      "IEPN", "id del extranjero PN no residente en Colombia", this.personType.NATURAL, 
      this.patternType.ALPHANUMERIC, LENGTH_SIXTEEN),
    IEPJ: new DocumentPerson(
      "IEPJ", "id del extranjero PN no residente en Colombia", this.personType.LEGAL, 
      this.patternType.ALPHANUMERIC, LENGTH_SIXTEEN),
    RC: new DocumentPerson(
      "RC", "Registro Civil", this.personType.NATURAL, this.patternType.ALPHANUMERIC, LENGTH_ELEVEN),
    TI: new DocumentPerson(
      "TI", "Tarjeta De Identidad", this.personType.NATURAL, this.patternType.NUMERIC, LENGTH_ELEVEN)
  }

  public getDocumentPerson(codeDocument: string): DocumentPerson {
    if (this.documents[codeDocument]) {
      return this.documents[codeDocument];
    } else {
      return this.documents.CC;
    }
  }
}