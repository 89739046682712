import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ChangePassword } from 'src/app/shared/models/changePassword';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { ChangePasswordService } from 'src/app/shared/services/changePassword/change-password.service';
import { LogOutService } from 'src/app/shared/services/logOut/log-out.service';
import { ParamService } from 'src/app/shared/services/param/param.service';

@Component({
  selector: 'app-confirm-new-password',
  templateUrl: './confirm-new-password.component.html',
  styleUrls: ['./confirm-new-password.component.css']
})

export class ConfirmNewPasswordComponent implements OnInit {
  newPasswordForm: UntypedFormGroup;
  email: string;
  password: string;
  loading: boolean;
  submitted: boolean;
  changePassword: ChangePassword;

  constructor( private rutaActiva: ActivatedRoute,
               private formBuilder: UntypedFormBuilder,
               public router: Router,
               public alertService: AlertService,
               public changePasswordService: ChangePasswordService,
               private paraService: ParamService,
               private logOutService: LogOutService) { }

  ngOnInit() {

    this.email = this.paraService.getEmail();
    this.password = this.paraService.getPassword();

    this.newPasswordForm = this.formBuilder.group({
      NewPass: ['', Validators.required],
      NewPassOk: ['', Validators.required],
      captcha:['', Validators.required]
    });
  }

  get f() { return this.newPasswordForm.controls; }

  onSubmit() {
    if (this.newPasswordForm.invalid) {
      return;
    }

    if (this.newPasswordForm.get('NewPass').value !== this.newPasswordForm.get('NewPassOk').value) {
      this.alertService.error('Las contraseñas deben coincidir.');
      return;
    }
    this.submitted = true;

    this.changePassword = new ChangePassword();
    this.changePassword.emailAddr = this.email;
    this.changePassword.oldPassword = this.password;
    this.changePassword.newPassword = this.newPasswordForm.get('NewPass').value;
    this.changePassword.verifyPassword = this.newPasswordForm.get('NewPassOk').value;
    this.changePassword.captcha = this.newPasswordForm.get('captcha').value;

    this.changePasswordFunction();

  }

  private changePasswordFunction() {
    this.changePasswordService.changePassword(this.changePassword)
      .pipe(first())
      .subscribe(
        data => {
          if (data.codeReturn == '1') {
            this.loading = false;
            this.logOutService.logOut().subscribe(
              () => {
                this.router.navigate(['/login']);
                this.alertService.success('Se cambió la contraseña correctamente');

              },
              error => {
                this.router.navigate(['/login']);
                this.alertService.error(data.message);

              });

          } else {
            this.alertService.error(data.message);
          }

        }, error => {
          if (error) {
            if (error.statusText === 'Unknown Error') {
              this.alertService.error('¡Lo sentimos! No ha sido posible enviar la solicitud por una falla del sistema. Por favor vuelva a intentarlo mas tarde.');
            } else {
              this.alertService.error(error);
            }
          } else {
            this.alertService.error('¡Lo sentimos! No ha sido posible enviar la solicitud por una falla del sistema. Por favor vuelva a intentarlo mas tarde.');
          }
          this.loading = false;
        });
    this.submitted = false;
  }
}
