import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientValidateResponse } from 'src/app/shared/models/clientValidateResponse';
import { CommonFunctions } from 'src/app/shared/models/commonFunctions';
import { DocumentPerson } from 'src/app/shared/models/documentPerson';
import { ClientResponseFormalization } from 'src/app/shared/models/formalization-clients/client-response-formalization';
import { TypeFileDTO } from 'src/app/shared/models/typeFileDTO';
import { BcLoadingService } from 'src/app/shared/services/commons/bc-loading/bc-loading.service';
import { HighRiskDocumentsService } from 'src/app/shared/services/high-risk-documents/high-risk-documents.service';
import { ParamService } from 'src/app/shared/services/param/param.service';
import { environment } from 'src/environments/environment';

const LINK_FC_PERSONA_NATURAL = environment.linkPersonaNatural;
const LINK_FC_PERSONA_JURIDICA = environment.linkPersonaJuridica;
const LINK_SOCIOS_ACCIONISTAS = environment.linkSociosAccionistas;

@Component({
  selector: 'app-blocked-client',
  templateUrl: './blocked-client.component.html',
  styleUrls: ['./blocked-client.component.css']
})
export class BlockedClientComponent implements OnInit{

  mayorGestion:boolean;
  public clientValidateResponse:ClientValidateResponse;
  clientResponseFormalization:ClientResponseFormalization = new ClientResponseFormalization();
  commonFunctions: CommonFunctions;
  documentPerson: DocumentPerson;
  public typePerson: string = "";
  public documents: TypeFileDTO[] = [];

  public links = [
    {
      id: 40,
      description: "formato conocimiento persona natural",
      link: LINK_FC_PERSONA_NATURAL,
    },
    {
      id: 41,
      description: "formato conocimiento persona juridica",
      link: LINK_FC_PERSONA_JURIDICA,
    },
    {
      id: 11,
      description: "Formato de relación de socios accionistas.",
      link: LINK_SOCIOS_ACCIONISTAS,
    },
  ];

  public aditionalDocumentationClientNatural =
  "/assets/documents/documentosAdicionalesPersonaNatural.pdf";
  public aditionalDocumentationClientJuridica =
  "/assets/documents/documentosAdicionalesPersonaJuridica.pdf";

  constructor(private activateRoute: ActivatedRoute,
    public router: Router,public paramService: ParamService,
    private bcLoadingService: BcLoadingService,
    public highRiskDocumentsService: HighRiskDocumentsService){
      this.paramService.getValidarCliente().pipe().subscribe(data => {this.clientValidateResponse = data;}); 
    }

  ngOnInit(){
    let type = this.activateRoute.snapshot.paramMap.get("typeBlocking");
    if(type === "ALERT"){
      this.mayorGestion = false
    }else if(type === "VALIDATE"){
      this.mayorGestion = true;
    }else{
      this.goBackFormalization();
    }  

    this.clientResponseFormalization.nameClient= this.clientValidateResponse.firstName;
    this.clientResponseFormalization.fullnameClient= this.clientValidateResponse.fullName;
    this.clientResponseFormalization.documentType = this.clientValidateResponse.documentType;
    this.clientResponseFormalization.documentNumber = this.clientValidateResponse.documentNumber;
    this.clientResponseFormalization.activity = this.clientValidateResponse.activity;
    this.clientResponseFormalization.isHighlyRiskCustomer = this.clientValidateResponse.isHighlyRiskCustomer;

    this.loadDocumentsMayorGestionFormalization(this.clientResponseFormalization.documentType, this.clientResponseFormalization.documentNumber);
  }

  goBackFormalization(){
    this.router.navigate(["/advisor-home-novelty-view", this.clientValidateResponse.noveltyId]);
  }


  loadDocumentsMayorGestionFormalization(documentType: string, documentNumber: string) {
    this.bcLoadingService.show();
    this.highRiskDocumentsService
      .listHighRiskDocuments(documentType, documentNumber)
      .subscribe(
        (data) => {
          this.bcLoadingService.close();
          this.documents = data.documents;
          this.typePerson = data.typePerson;
        },
        (_) => {
          this.bcLoadingService.close();
        }
      );
  }

  public getLink(id: number): string {
    const link = this.links.find((x) => x.id == id);
    if (link !== undefined) {
      return link.link;
    }

    return undefined;
  }

  public getAditionalDocuments(): string {
    if (this.typePerson === "Persona Natural") {
      return this.aditionalDocumentationClientNatural;
    }

    return this.aditionalDocumentationClientJuridica;
  }

}
