import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {
  anio: number;
  constructor() {
    const fecha = new Date();
    this.anio = fecha.getFullYear();
   }

  ngOnInit() {
    //has no implementation
  }

}
