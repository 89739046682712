import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from 'src/app/config/config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { TokenAdmin } from '../../models/TokenAdmin';
import { ChangePassword } from '../../models/changePassword';
import { ResponseService } from '../../models/response-service';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {
  private urlEndPoint = URL_SERVICIOS;
  private tokenAdmin: TokenAdmin = new TokenAdmin();
  private token = this.tokenAdmin.getToken();
  private serviceChangePassword = null;
  private httpHeaders = null;

  constructor( private http: HttpClient ) { }

  changePassword( changePassword: ChangePassword ) {
    this.serviceChangePassword = 'changepassword';
    this.httpHeaders = new HttpHeaders({'Content-Type' : 'application/json',
    Authorization: 'Bearer ' + this.token });

    return this.http.post<ResponseService>
    (`${this.urlEndPoint}/${this.serviceChangePassword}`, changePassword , {headers: this.httpHeaders});
  }

  changePasswordLogin( changePassword: ChangePassword ) {
    this.serviceChangePassword = 'changepassword/login';

    this.token = this.tokenAdmin.getToken();
    this.httpHeaders = new HttpHeaders({'Content-Type' : 'application/json',
    Authorization: 'Bearer ' + this.token });

    return this.http.post<ResponseService>
    (`${this.urlEndPoint}/${this.serviceChangePassword}`, changePassword , {headers: this.httpHeaders});
  }

}
