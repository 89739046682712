import { Component, OnInit, Input } from '@angular/core';
import { DocumentsDetailsConstants } from 'src/app/shared/constants/DocumentsDetailsConstants';
import { ClientValidateResponse } from 'src/app/shared/models/clientValidateResponse';
import { CommonFunctions } from 'src/app/shared/models/commonFunctions';
import { DocumentPerson } from 'src/app/shared/models/documentPerson';
import { LogResponse } from 'src/app/shared/models/logResponse';
@Component({
  selector: 'app-documents-details',
  templateUrl: './documents-details.component.html',
  styleUrls: ['./documents-details.component.css']
})
export class DocumentsDetailsComponent implements OnInit {

  commonFunctions: CommonFunctions;
  documentPerson: DocumentPerson;

  actualizacion: boolean = false;
  alerta: boolean = false;

  nombrecliente: string;
  tipodocumento: string;
  numerodocumento: string;

  titulo: string;
  SUB_TITULO: string;
  mensaje: string;
  mensaje2: string;
  mensaje3: string;
  mensaje4: string;
  mensaje41: string;
  mensaje5: string;
  encabezados: string[] = [];

  DOCUMENTOS_COL1: any[] = [];
  DOCUMENTOS_COL2: any[] = [];
  DOCUMENTOS_COL3: any[] = [];
  clientValidateResponse: ClientValidateResponse;

  isFullSize: boolean = false;

  isModal: boolean = false;

  public forceShowDocumentsCol2 = false;

  public linkEncabezado6 = undefined;

  constructor() {
    this.commonFunctions = new CommonFunctions();
  }

  ngOnInit() {
    //has no implementation
  }

  loadData() {
    this.resetData();
    this.setDocumentPerson();

    const action = this.determineAction();
    if (action) {
        action.call(this);
    }
  }

  resetData() {
      this.linkEncabezado6 = undefined;
      this.tipodocumento = this.clientValidateResponse.documentType;
  }

  setDocumentPerson() {
      if (this.clientValidateResponse.personType !== "PERSONA NATURAL"
          && this.clientValidateResponse.personType !== "PERSONA JURIDICA") {
          this.documentPerson = this.commonFunctions.getDocumentPerson(this.tipodocumento);
          this.clientValidateResponse.personType = this.documentPerson.personType;
      } else {
          this.documentPerson = this.commonFunctions.getDocumentPerson(this.tipodocumento);
          this.tipodocumento = this.documentPerson.documentDescribe;
      }
  }

  determineAction() {
    const { controlListStatus, personType, documentType, customerManagerStatus } = this.clientValidateResponse;
    
    let action = this.actionConfigControlList[controlListStatus]?.[personType]?.[documentType] ||
                 this.actionConfigControlList[controlListStatus]?.[personType]?.["default"] ||
                 this.actionConfig[customerManagerStatus]?.[personType]?.[documentType] ||
                 this.actionConfig[customerManagerStatus]?.[personType]?.["default"];

    return action;
}

get actionConfig() {
    return {
        "NO_EXISTE": {
          "PERSONA JURIDICA": {
              "NIT": this.setupVinculationDocumentsNIT,
              "IEPJ": this.setupVinculationDocumentsIEPJ
          },
          "PERSONA NATURAL": {
              "default": this.setupVinculationDocumentsNaturalPerson
          }
        },
        "EXISTE_DESACTUALIZADO": {
          "PERSONA NATURAL": {
              "default": this.setupOutdatedDocumentsNaturalPerson
          },
          "PERSONA JURIDICA": {
              "default": this.setupOutdatedDocumentsLegalPerson
          }
        }
    };
 }

  get actionConfigControlList() {
    return {
      "ALERTA": {
          "PERSONA JURIDICA": {
              "NIT": this.setupAlertDocumentsNIT,
              "IEPJ": this.setupAlertDocumentsIEPJ
          },
          "PERSONA NATURAL": {
              "default": this.setupAlertDocumentsNaturalPerson
          }
      }
    };
  }

  setupAlertDocumentsNIT() {
      this.getAlertDocumentsNIT();
  }

  setupAlertDocumentsIEPJ() {
      this.getAlertDocumentsIEPJ();
  }

  setupAlertDocumentsNaturalPerson() {
    this.encabezados[DocumentsDetailsConstants.SIZE_HEADER_ZERO] = "Documentos de vinculación:";
      this.encabezados[DocumentsDetailsConstants.SIZE_HEADER_ONE] = "Personas Naturales con establecimiento de comercio";
      this.getAlertDocumentsNaturalPerson();
  }

  setupVinculationDocumentsNIT() {
      this.encabezados[DocumentsDetailsConstants.SIZE_HEADER_ZERO] = "Documentos de vinculación:";
      this.forceShowDocumentsCol2 = true;
      this.getVinculationDocumentsNIT();
  }

  setupVinculationDocumentsIEPJ() {
    this.encabezados[DocumentsDetailsConstants.SIZE_HEADER_ZERO] = "Documentos de vinculación:";
    this.forceShowDocumentsCol2 = true;
    this.getVinculationDocumentsIEPJ();
  }

  setupVinculationDocumentsNaturalPerson() {
    this.encabezados[DocumentsDetailsConstants.SIZE_HEADER_ZERO] = "Documentos de vinculación:";
    this.encabezados[DocumentsDetailsConstants.SIZE_HEADER_ONE] = "Documentos que soportan la actividad económica:";
    this.encabezados[DocumentsDetailsConstants.SIZE_HEADER_TWO] = "Persona Natural menor de edad:";
    this.getVinculationDocumentsNaturalPerson();
  }

  setupOutdatedDocumentsNaturalPerson() {
    this.encabezados[DocumentsDetailsConstants.SIZE_HEADER_ZERO] = "";
    this.encabezados[DocumentsDetailsConstants.SIZE_HEADER_THREE] = "";
    this.encabezados[DocumentsDetailsConstants.SIZE_HEADER_FOUR] = "Actualización a través de la Sucursal Virtual Personas";
    this.encabezados[DocumentsDetailsConstants.SIZE_HEADER_FIVE] = "Actualización a través del ";
    this.encabezados[DocumentsDetailsConstants.SIZE_HEADER_SIX] = "“Formato de conocimiento del cliente persona natural”";
    this.encabezados[DocumentsDetailsConstants.SIZE_HEADER_SEVEN] = "Recuerda: El formulario no puede tener enmendaduras o tachones.";
    this.linkEncabezado6 = DocumentsDetailsConstants.LINK_FC_PERSONA_NATURAL;
    this.getOutdatedDocumentsNaturalPerson();
    this.mensaje = '';
    this.mensaje2 = '';
    this.mensaje3 = '';
    this.mensaje4 = `Debe ingresar en la Sucursal Virtual Personas a la opción “Actualizar mis datos” que encontrará en la parte superior `
    this.mensaje41 = `derecha justo abajo de su nombre. Al terminar, los datos se actualizarán de forma inmediata en el Link Sala de ventas.`;
    this.mensaje5 = 'Para que la actualización sea válida debe diligenciar mínimo los siguientes datos:'

    this.isFullSize = true;
  }

  setupOutdatedDocumentsLegalPerson() {
    this.encabezados[DocumentsDetailsConstants.SIZE_HEADER_ZERO] = "";
    this.encabezados[DocumentsDetailsConstants.SIZE_HEADER_SEVEN] = "Recuerda: El formulario no puede tener enmendaduras o tachones.";
    this.encabezados[DocumentsDetailsConstants.SIZE_HEADER_FOUR] = "";
    this.encabezados[DocumentsDetailsConstants.SIZE_HEADER_FIVE] = "Actualización a través del ";
    this.encabezados[DocumentsDetailsConstants.SIZE_HEADER_SIX] = "Formato de conocimiento del cliente persona juridica.";
    this.linkEncabezado6 = DocumentsDetailsConstants.LINK_FC_PERSONA_JURIDICA;
    this.getOutdatedDocumentsLegalPerson();
    this.mensaje = '';
    this.mensaje2 = '';
    this.mensaje3 = '';
    this.mensaje4 = '';
    this.mensaje41 = '';
    this.mensaje5 = 'Para que la actualización sea válida debe diligenciar mínimo los siguientes datos:';
    this.isFullSize = true;
  }

  private getOutdatedDocumentsLegalPerson() {
    this.DOCUMENTOS_COL1 = [
      { Texto: "Fecha diligenciamiento", link: false, sin_link: true },
      { Texto: "Número de documento", link: false, sin_link: true },
      { Texto: "Razón social", link: false, sin_link: true },
      { Texto: "Firma", link: false, sin_link: true },
    ];

    this.DOCUMENTOS_COL2 = [];
  }

  private getOutdatedDocumentsNaturalPerson() {
    this.DOCUMENTOS_COL1 = [
      { Texto: "Fecha diligenciamiento", link: false, sin_link: true },
      { Texto: "Nombres y apellidos", link: false, sin_link: true },
      { Texto: "Tipo y número de documento de identidad", link: false, sin_link: true },
      { Texto: "Actividad económica. Si la actividad es independiente debe diligenciar el código CIIU", link: false, sin_link: true },
      { Texto: "Firma", link: false, sin_link: true },
    ];

    this.DOCUMENTOS_COL2 = [];
  }

  private getVinculationDocumentsNaturalPerson() {
    this.DOCUMENTOS_COL1 = [{
      Texto: "Formato de conocimiento persona natural.",
      vinculo: DocumentsDetailsConstants.LINK_FC_PERSONA_NATURAL,
      link: true, sin_link: false
    },
    { Texto: "Copia del documento de identificación.", vinculo: "", link: false, sin_link: true }];


    this.DOCUMENTOS_COL2 = [{
      Texto: "Certificación Laboral para empleados.", vinculo: "",
      link: false, sin_link: true
    },
    {
      Texto: "Para independientes Certificación de ingresos firmado por el contador y fotocopia tarjeta "
        + "profesional de contador (o documento que pruebe que esa persona que expide el certificado es contador).",
      vinculo: "", link: false, sin_link: true
    },

    { Texto: "Para pensionados Certificación de pensión.", vinculo: "", link: false, sin_link: true },
    {
      Texto: "Para estudiantes Certificado de estudios o copia de carnet vigente.",
      vinculo: "", link: false, sin_link: true
    },
    {
      Texto: "Para dependientes Carta de dependencia económica con anexo de soporte económico de quien certifica.",
      vinculo: "", link: false, sin_link: true
    }];



    this.DOCUMENTOS_COL3 = [{
      Texto: "Formato de conocimiento de persona natural con los datos del menor"
        + " firmado por AMBOS padres y Formato de conocimiento de persona natural de cada uno de los padres.",
      vinculo: "", link: false, sin_link: true
    }];
  }

  private getVinculationDocumentsIEPJ() {
    this.DOCUMENTOS_COL1 = [{
      Texto: "Formato de conocimiento persona jurídica.",
      vinculo: DocumentsDetailsConstants.LINK_FC_PERSONA_JURIDICA,
      link: true, sin_link: false
    },
    {
      Texto: "Formato de relación de socios accionistas.",
      vinculo: DocumentsDetailsConstants.LINK_SOCIOS_ACCIONISTAS,
      link: true, sin_link: false
    },
    { Texto: "Certificado de existencia y representación legal", vinculo: "", link: false, sin_link: true }];


    this.DOCUMENTOS_COL2 = [{
      Texto: "Documento que apruebe atribuciones representante legal",
      vinculo: "", link: false, sin_link: true
    },
    {
      Texto: "Copia del documento de identidad del representante legal", vinculo: "",
      link: false, sin_link: true
    }];
  }

  private getVinculationDocumentsNIT() {
    this.DOCUMENTOS_COL1 = [{
      Texto: "Formato de conocimiento persona jurídica.",
      vinculo: DocumentsDetailsConstants.LINK_FC_PERSONA_JURIDICA,
      link: true, sin_link: false
    },
    {
      Texto: "Formato de relación de socios accionistas.",
      vinculo: DocumentsDetailsConstants.LINK_SOCIOS_ACCIONISTAS,
      link: true, sin_link: false
    },
    { Texto: "Rut mínino del año anterior.", vinculo: "", link: false, sin_link: true },
    { Texto: "Cámara de comercio con vigencia de 90 días.", vinculo: "", link: false, sin_link: true },
    { Texto: "Copia de cédula de representante legal.", vinculo: "", link: false, sin_link: true }];
  }

  private getAlertDocumentsNaturalPerson() {
    this.DOCUMENTOS_COL1 = [{
      Texto: "Copia del documento de identificación.",
      vinculo: "", link: false, sin_link: true
    },
    {
      Texto: "Formato de conocimiento de cliente de persona natural completamente diligenciado.",
      vinculo: DocumentsDetailsConstants.LINK_FC_PERSONA_NATURAL,
      link: true, sin_link: false
    },
    {
      Texto: "Para empleados, carta laboral con fecha de expedición no"
        + " mayor a 30 días y en los demás casos certificado de contador.",
      vinculo: "", link: false, sin_link: true
    },
    ];


    this.DOCUMENTOS_COL2 = [{
      Texto: "Certificado de matrícula mercantil vigente.",
      vinculo: "", link: false, sin_link: true
    },
    { Texto: "Copia del RUT para declarantes.", vinculo: "", link: false, sin_link: true },
    {
      Texto: "Estados financieros de los dos últimos períodos para personas naturales.",
      vinculo: "", link: false, sin_link: true
    }];
  }

  private getAlertDocumentsIEPJ() {
    this.DOCUMENTOS_COL1 = [{
      Texto: "Certificado de existencia y representación legal para persona jurídica.",
      vinculo: "", link: false, sin_link: true
    },
    {
      Texto: "Formato diligenciado de socios y accionistas.",
      vinculo: DocumentsDetailsConstants.LINK_SOCIOS_ACCIONISTAS,
      link: true, sin_link: false
    },
    {
      Texto: "Listado de los principales clientes y lista de proveedores.",
      vinculo: "", link: false, sin_link: true
    },
    {
      Texto: "Formato de conocimiento de persona juridica completamente diligenciado.",
      vinculo: DocumentsDetailsConstants.LINK_FC_PERSONA_JURIDICA,
      link: true, sin_link: false
    }];

    this.DOCUMENTOS_COL2 = [{
      Texto: "Documento que apruebe atribuciones representante legal",
      vinculo: "", link: false, sin_link: true
    },
    {
      Texto: "Copia del documento de identidad del representante legal", vinculo: "",
      link: false, sin_link: true
    }];
  }

  private getAlertDocumentsNIT() {
    this.DOCUMENTOS_COL1 = [{
      Texto: "Certificado de existencia y representación legal para persona jurídica.",
      vinculo: "", link: false, sin_link: true
    },
    {
      Texto: "Formato diligenciado de socios y accionistas.",
      vinculo: DocumentsDetailsConstants.LINK_SOCIOS_ACCIONISTAS,
      link: true, sin_link: false
    },
    {
      Texto: "Listado de los principales clientes y lista de proveedores.",
      vinculo: "", link: false, sin_link: true
    },
    {
      Texto: "Formato de conocimiento de persona juridica completamente diligenciado.",
      vinculo: DocumentsDetailsConstants.LINK_FC_PERSONA_JURIDICA,
      link: true, sin_link: false
    }];

    this.DOCUMENTOS_COL2 = [{
      Texto: "Copia del RUT para declarantes, personas juridicas con establecimiento de comercio.",
      vinculo: "", link: false, sin_link: true
    },
    {
      Texto: "Estados financieros de los 2 últimos periodos.", vinculo: "",
      link: false, sin_link: true
    }];
  }

  set data(log: LogResponse) {

    this.isModal = true;
    this.clientValidateResponse = new ClientValidateResponse();
    this.clientValidateResponse.controlListStatus = log.controlList;
    this.clientValidateResponse.customerManagerStatus = log.mdm;
    this.clientValidateResponse.personType = log.personType;
    this.clientValidateResponse.documentType = log.documentType;
    this.clientValidateResponse.documentNumber = log.documentNumber;
    this.clientValidateResponse.firstName = log.client;
    this.loadData();
  }

  @Input()
  set dataResponse(clientValidateResponse: ClientValidateResponse) {
    this.clientValidateResponse = clientValidateResponse;
    this.isModal = false;
    if (this.clientValidateResponse) {
      this.loadData();
    }
  }

}
