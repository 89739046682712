import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenAdmin } from '../../models/TokenAdmin';
import { ParamService } from '../../services/param/param.service';
import { PermissionService } from '../permission/permission.service';



@Injectable()
export class CanActivatePortalService implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;
  private tokenAdmin= new TokenAdmin();
 
    constructor(private permissions: PermissionService, private paramService: ParamService, private router: Router) {}
  
    canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
      //seteamos por default
      this.paramService.setPermission(this.tokenAdmin.getPermission());
      return this.hasPermissions('/'+route.routeConfig.path);
    }    


    hasPermissions(url:string){
      const hasPermission:boolean = this.permissions.hasPermission(url);
      if(!hasPermission&&!this.paramService.getPermission()){
        // redirigir login
        this.router.navigate(['/login']);
      }else if(!hasPermission&&this.paramService.getPermission()&&url!=this.paramService.getPermission()['home']){
        //redirigir al home
        this.router.navigate([this.paramService.getPermission()['home']]);
      }

      return hasPermission;
    }
  
}
