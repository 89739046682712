<main>
  <div>
    <p>
      <bc-icon class="icon-backgroud" size="md" 
      name="alert" height="30px" aria-hidden="true" 
      aria-label="prueba"></bc-icon>
    </p>
  </div>

  <h2>Sesión inactiva</h2>
  <div>
    <p>No hemos detectado actividad en los
      últimos {{timeWarning}} minutos, por su seguridad la sesión se cerrará en los proximos minutos.</p>
  </div>
  <button type="button" bc-button (click)="cerrar()">ENTENDIDO</button>
</main>