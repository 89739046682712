import { Injectable } from '@angular/core';


const MIN_LEN_TEXT = 3;

@Injectable({
  providedIn: 'root'
})
export class SearchTextService {

  private dateFieldsArray: string[] = [];
  private filterBy: string[] = [];

  constructor() { 
    //has no implementation
  }

  set filterByFields(data: string[]) {
    this.filterBy = data;
  }

  set dateFields(data: string[]) {
    this.dateFieldsArray = data;
  }

  filtrar(dataValue: any[], filterText: string) {
    const elementsSearch: any[] = this.clone(dataValue);
    let resultItems: any[] = new Array<any>();
    if (!filterText || filterText.length < MIN_LEN_TEXT) {
      resultItems = elementsSearch;
    } else {

      for (const item of elementsSearch) {
        if (this.filter(item, filterText)) {
          resultItems.push(item);
        }

      }
    }
    return resultItems;
  }

  private clone(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  private filter(item: any, valor: string) {
    for (const key of this.filterBy) {
      const isDate = this.isDateField(key);
      const keySplit = key.split('.');
      const value = (isDate) ? this.strToDate(item[key]) : (keySplit.length > 1) ? item[keySplit[0]] : item[key];
      const isObject = (value instanceof Object);
      if (!isObject && this.strContains(value, valor)) {
        item[key] = (isDate) ? item[key] : this.markText(value + '', valor);
        return true;
      } else if (isObject && this.searchInObject(item, keySplit, valor)) {
        return true;

      }
    }
    return false;
  }

  private searchInObject(item, keyArray, searchText) {
    let object = item[keyArray[0]];
    const arraySize = keyArray.length;
    for (let i = 1; i < arraySize - 1; i++) {
      const keyItem = keyArray[i];
      if (keyItem == 'i') {
        const newKeyArray = keyArray.slice(i + 1, arraySize);
        for (const index in object) {
          if (this.searchInObject(object[index], newKeyArray, searchText)) {
            return true;
          }

        }
        return false;
      } else {
        object = object[keyItem];
      }

    }

    const value = object[keyArray[arraySize - 1]];
    if (this.strContains(value, searchText)) {
      object[keyArray[arraySize - 1]] = this.markText(value + '', searchText);
      return true;
    }
    return false;
  }

  private strContains(value: string, searchText: string) {
    return (value + '').toLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1;
  }


  private strToDate(date: string) {
    const options:any = {
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
    return new Date(date).toLocaleString('es-co', options);
  }

  private isDateField(key: any) {
    for (const index in this.dateFieldsArray) {
      if (this.dateFieldsArray[index] === key) {
        return true;
      }
    }
    return false;
  }


  private markText(strOr: string, search: string): string {
    let i = 0;
    let out = '';
    while (i < strOr.length) {
      const tam = search.length + i;
      const text = strOr.substring(i, tam);
      if (text.toLowerCase() == search.toLowerCase()) {
        out = strOr.substring(0, i) +
          '<strong>' + strOr.substring(i, tam) + '</strong>' +
          strOr.substring(tam, strOr.length);
        i = strOr.length;
      }
      i++;
    }
    return out;
  }
}
