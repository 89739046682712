import { Component, OnInit, ViewChild, ElementRef, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Location } from '@angular/common';
import { AlertChangePasswordComponent } from '../alert-change-password/alert-change-password.component';
import { Login } from 'src/app/shared/models/login';
import { TokenAdmin } from 'src/app/shared/models/TokenAdmin';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/shared/services/login/login.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { ParamService } from 'src/app/shared/services/param/param.service';
import { PermissionService } from 'src/app/shared/services/permission/permission.service';
import { ForgotPasswordService } from 'src/app/shared/services/forgotPassword/forgot-password.service';
import { LogOutService } from 'src/app/shared/services/logOut/log-out.service';
import { BcLoadingService } from 'src/app/shared/services/commons/bc-loading/bc-loading.service';
import { BcModalServiceService } from 'src/app/shared/services/commons/bc-modal-service/bc-modal-service.service';
import { ResponseService } from 'src/app/shared/models/response-service';
import { UserMenuCommunicationService } from 'src/app/modules/administrator/services/user-menu-comunication/user-menu-communication.service';

const CANT_INTENTOS = 3;

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})

export class PasswordComponent implements OnInit {

 //variables
  MAX_INTENTOS = CANT_INTENTOS;
  email: string;
  password: string;
  loginInfo: Login;
  passwordForm: UntypedFormGroup;
  submitted = false;
  mess: string[] ;
  user: UntypedFormGroup;
  intentos: number;
  loading: boolean;
  isForgotExecuted: boolean = false;
  private tokenAdmin: TokenAdmin = new TokenAdmin();
  isVisible:boolean=false;
  isLogginExecuted:boolean=false;
  captchaEnable:any = JSON.parse(environment.captchaEnabled);
  tagFluxName:string = 'login';
  tagEvent: string = 'SEND_AUTHENTICATION';
  isLogginRequest: boolean = false;

  @ViewChild('formLogin', {
    read: ElementRef
  }) loginFormRef: ElementRef;

  private formBuilder: UntypedFormBuilder;
  public router: Router;
  public loginService: LoginService;
  public alertService: AlertService;
  public paramService: ParamService;
  public permissionService: PermissionService;
  public forgotPasswordService: ForgotPasswordService;
  private location: Location;
  private logOutService: LogOutService;
  private bcLoadingService: BcLoadingService;
  private bcModalServiceService: BcModalServiceService;
  private userMenuCommunicationService: UserMenuCommunicationService;

  constructor(
    private injector : Injector
  ) {
    this.formBuilder = injector.get<UntypedFormBuilder>(UntypedFormBuilder);
    this.router = injector.get<Router>(Router);
    this.loginService = injector.get<LoginService>(LoginService);
    this.alertService = injector.get<AlertService>(AlertService);
    this.paramService = injector.get<ParamService>(ParamService);
    this.permissionService = injector.get<PermissionService>(PermissionService);
    this.forgotPasswordService = injector.get<ForgotPasswordService>(ForgotPasswordService);
    this.location = injector.get<Location>(Location);
    this.logOutService = injector.get<LogOutService>(LogOutService);
    this.bcLoadingService = injector.get<BcLoadingService>(BcLoadingService);
    this.bcModalServiceService = injector.get<BcModalServiceService>(BcModalServiceService);
    this.userMenuCommunicationService = injector.get<UserMenuCommunicationService>(UserMenuCommunicationService);
  }

  get f() { return this.passwordForm.controls; }

  ngOnInit() {
    this.intentos = 0;

    this.email = this.paramService.getEmail();
    this.passwordForm = this.formBuilder.group({
      password: ['', Validators.required],
      captcha:[]
    });
  }

  loginRequest(){
    if(this.isLogginRequest){ return;}
    this.isLogginRequest = true;
    this.loginInfo.captcha = this.f.captcha.value;
    this.loginService.login( this.loginInfo )
    .pipe(first())
    .subscribe(
        data => {
          this.validateLogin(data);
        },
        error => {
          this.bcLoadingService.close();
          this.isLogginExecuted = false;
          this.isLogginRequest = false;
          this.f.captcha.setValue(null);
          this.mess =  [error.message];
          this.alertService.error("¡Lo sentimos! No ha sido posible iniciar sesión. El Link Sala de Ventas solamente está disponible de 5:00 a.m. a 9:00 p.m.");
        });

  }

  private validateLogin(data: ResponseService) {
    this.bcLoadingService.close();
    this.isLogginExecuted = false;
    this.isLogginRequest = false;
    this.f.captcha.setValue(null);
    switch (data.codeReturn) {
      case '1': {
        //login sucess
        this.permissionService.guardarToken(data.token);
        this.permissionService.saveIp(data.ip);
        this.userMenuCommunicationService.setTemporaryVariable(true);
        this.router.navigate([this.tokenAdmin.getPermission()['home']]);
        this.logOutService.startTimer();
        break;
      }
      case '2': {
        this.permissionService.guardarToken(data.token);
        this.paramService.setEmail(this.email);
        this.paramService.setPassword(this.loginInfo.password);
        this.logOutService.logOutBackground();
        //Cambio de Contraseña;
        this.router.navigate(['/confirmnewpassword']);
        break;
      }
      case '3': {
        this.bcModalServiceService.showWithDataHideClose(AlertChangePasswordComponent, {
          callBack: this.forgotPasswordRequest.bind(this)
        });
        break;
      }
      default: {
        //Error;
        this.alertService.error(data.message);
        break;

      }
    }
  }

  login(captchaRef) {
    sessionStorage.removeItem('stateFilter');
    sessionStorage.removeItem('indexarFilter');
    sessionStorage.removeItem('requestTypeFilter');
    sessionStorage.removeItem('searchTextFilter');
    if(this.disableButton()) { return; }
    this.bcLoadingService.show();
    this.loginInfo = new Login();
    this.loginInfo.emailAddr = this.email;
    this.loginInfo.password = this.passwordForm.get('password').value;


    this.intentos++;

    if (this.loginInfo.password.trim().length <= 0 ) {
      this.bcLoadingService.close();
      this.alertService.error('La contraseña es requerida');
    } else if (this.isVisible || !this.captchaEnable) {
      this.loginRequest();
    } else {
      this.isLogginExecuted = true;
      captchaRef.reset();
      captchaRef.execute();
    }
  }


disableButton(){
 return  (this.f.password.value.length == 0 || !this.captchaIsValid()  );
}

captchaIsValid() {
  this.isVisible = (this.intentos >= this.MAX_INTENTOS);
  return (this.intentos < this.MAX_INTENTOS || this.f.captcha.value || !this.captchaEnable);
}

resolved(captchaResponse: string) {
  if (this.isLogginExecuted) {
  this.f.captcha.setValue(captchaResponse);
  this.loginRequest();
  }else if(this.isForgotExecuted){
    this.f.captcha.setValue(captchaResponse);
    this.forgotPasswordRequest();
  }
}

disableButtom() {
  let datosValidos = false;
  if ( this.intentos <= this.MAX_INTENTOS ) {
    if (this.passwordForm.value.password.trim().length > 0 ) {
      datosValidos = true;
    }
  } else {
    datosValidos = !this.passwordForm.invalid;
  }

  return !datosValidos;
}

goBack() {
  this.location.back();
}

enter(){
  if(!this.disableButton()){
    const button = this.loginFormRef.nativeElement.querySelector('button');
    button.click();
  }

}

forgotPassword(captchaRef){
  this.bcLoadingService.show();
  this.isForgotExecuted = true;
  captchaRef.reset();
  captchaRef.execute();
}

forgotPasswordRequest() {

  const forgotData = {emailAddr: this.email, captcha: this.f.captcha.value}

  this.bcLoadingService.show();
  this.forgotPasswordService.forgotPassword(forgotData)
    .subscribe(
      data => {
        this.isForgotExecuted = false;
        this.bcLoadingService.close();
        if (data.codeReturn === '1') {
          this.paramService.setEmail(this.email);
          this.router.navigate(['/forgotPasswordCode']);
        } else if ( data.codeReturn === '2') {
          this.paramService.setEmail(this.email);
          this.router.navigate(['/oldpassword']);
          this.paramService.setIsSendEmail(true);

        } else {
          this.alertService.error(data.message);
        }
      },
      error => {
        this.isForgotExecuted = false;
        if (error) {
          if (error.statusText === 'Unknown Error') {
            this.alertService.error('¡Lo sentimos! No ha sido posible enviar la solicitud por una falla del sistema. Por favor vuelva a intentarlo mas tarde.');
          } else {
            this.alertService.error(error);
          }
        } else {
          this.alertService.error('¡Lo sentimos! No ha sido posible enviar la solicitud por una falla del sistema. Por favor vuelva a intentarlo mas tarde.');
        }
        this.bcLoadingService.close();
      });
}

}
