import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from 'src/app/config/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ParamService } from '../../services/param/param.service';
import { LoginService } from '../login/login.service';
import { AlertService } from '../alert/alert.service';
import { Router } from '@angular/router';
import { BcLoadingService } from '../commons/bc-loading/bc-loading.service';
import { TokenAdmin } from '../../models/TokenAdmin';
import { Rol } from '../../models/Rol';


@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  private urlEndPoint = URL_SERVICIOS;
  private service = null;
  private httpHeaders: any;
  private token: string;
  private tokenAdmin: TokenAdmin = new TokenAdmin();
  private ip: string;

  constructor(private http: HttpClient, private paramService: ParamService, public loginService: LoginService,
    public alertService: AlertService,
    public router: Router,
    private bcLoadingService: BcLoadingService,
  ) { }


  hasPermission(url: any) {

    let isvalid: boolean = false;
    if (!this.paramService.getPermission()) {
      return false;
    }
    this.paramService.getPermission()['listModule'].forEach(item => {

      if (item == url) {
        isvalid = true;
      }
    });

    return isvalid;
  }

  guardarToken(token: string) {
    this.tokenAdmin.setToken(token);
  }

  getEmailFromToken() {
    const token = this.tokenAdmin.getToken();
    if (token) {
      const temp = token.split('.');
      if (temp[1]) {
        const jsonJwt = JSON.parse(atob(temp[1]));
        return jsonJwt.sub;
      }
    }
    return null;
  }

  getRoles() {
    this.service = 'findallroles';
    this.token = this.tokenAdmin.getToken();
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.token
    });
    return this.http.get<Rol[]>(`${this.urlEndPoint}/${this.service}`, { headers: this.httpHeaders });

  }

  saveIp(ip: string): void {
    const index = ip.indexOf(",");
    const ipFinal = ip.substring(0, index);
    sessionStorage.setItem("ip", ipFinal !== "" ? ipFinal : ip);
    this.ip = ipFinal;
  }

  getIp(): string {
    return this.ip;
  }

}
