import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from 'src/app/config/config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TokenAdmin } from '../../models/TokenAdmin';
import { LogResponse } from '../../models/logResponse';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  private urlEndPoint = URL_SERVICIOS
  private service = 'logsfunctionals';
  private httpHeaders: any;
  private token: string;
  private tokenAdmin: TokenAdmin = new TokenAdmin();


  constructor(private http: HttpClient) { }


  getLog(pageIndex, pageSize) {
    this.token = this.tokenAdmin.getToken();
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json,charset=UTF-8',
      Authorization: 'Bearer ' + this.token
    });
    
    const params = new HttpParams()
      .set("pageIndex", pageIndex)
      .set("pageSize", pageSize);

    return this.http.get<LogResponse[]>(`${this.urlEndPoint}/${this.service}`, 
      { headers: this.httpHeaders, params: params });
  }
}
