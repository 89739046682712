import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { URL_SERVICIOS } from 'src/app/config/config';
import { ResponseService } from '../../models/response-service';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {
  private urlEndPoint = URL_SERVICIOS;
  
  private serviceForgotPassword = 'forgotpassword';

  private httpHeaders = new HttpHeaders({'Content-Type' : 'application/json'});

  constructor( private http: HttpClient ) { }

  forgotPassword( forgotData: any ) {
    return this.http.post<ResponseService>(`${this.urlEndPoint}/${this.serviceForgotPassword}`, forgotData , 
      {headers: this.httpHeaders});
  }
}
