import { Component } from '@angular/core';
import { BcModalServiceService } from 'src/app/shared/services/commons/bc-modal-service/bc-modal-service.service';

@Component({
  selector: 'app-modal-actualizar-session',
  templateUrl: './modal-actualizar-session.component.html',
  styleUrls: ['./modal-actualizar-session.component.css']
})
export class ModalActualizarSessionComponent {

  constructor(private bcModalServiceService:BcModalServiceService,) { }

  cerrar(){
    this.bcModalServiceService.close();
  }
}
