<main>
  <div class="row d-flex justify-content-between d-flex align-items-center">
    <div class="d-flex flex-column bd-highlight mb-3 no-margin">
      <div>
        <div class="d-flex justify-content-start">
          <a *ngIf="isBtnBack" [routerLink]="backRouterLink">
            <img id="arrow-image" src="/assets/images/arrow-left.svg" alt="arrow-left">
          </a>
          <div class="title text no-padding">{{title}}</div>
        </div>
      </div>
      <div class="sub-title text" *ngIf="hasSubtitle()">
        {{subTitle}}
      </div>
    </div>
    <div *ngIf="isFilterByText" class="filter-text filter-text-btn">
      <app-bc-search-input2 outerline=true realTime=false (filter)="filterText($event)">
      </app-bc-search-input2>
    </div>
    <div *ngIf="isButton">
      <button bc-button [typeButton]="typeButton" [disabled]="disabledButton" class="btn-action-style"
        (click)="actionButton()">
        {{titleButton}}</button>
    </div>
    <div *ngIf="isBackofficeOrHeadRelationship " class="filters">
      <app-filters-backoffice 
        (_filterText)="filterText($event)"
        (onFormGroupHeadRelationshipChange)="_onFormGroupHeadRelationshipChange($event)"
        (onFormGroupAuxiliaryBackofficeChange)="_onFormGroupAuxiliaryBackofficeChange($event)"
        [canSeeBackofficeTab]="canSeeBackofficeTab"
        [canFilterbyHeadRelationship]="canFilterbyHeadRelationship"
        [filteredheadRelationship]="filteredheadRelationship"
        [filteredAuxiliaryBackoffice]="filteredAuxiliaryBackoffice"
        [_currentTab]="_currentTab"
        >
      </app-filters-backoffice>
    </div>
  </div>
</main>