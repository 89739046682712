import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginatorEvent } from 'src/app/shared/models/custom-paginator-event';

const DEFAULT_LENGTH = 20;
const DEFAULT_PAGE_INDEX = 0;
const DEFAULT_PAGE_SIZE = 20;


@Component({
  selector: 'app-custom-paginator',
  templateUrl: './custom-paginator.component.html',
  styleUrls: ['./custom-paginator.component.css']
})
export class CustomPaginatorComponent extends MatPaginator implements OnInit {

  pageSizeIndex: number;
  pagesCount : number;
  retrieveNewPage: boolean;
  pageIndexNumber: any;

  @Output()
  page: EventEmitter<CustomPaginatorEvent> = new EventEmitter<CustomPaginatorEvent>();

  constructor( _intl: MatPaginatorIntl, _changeDetectorRef: ChangeDetectorRef) { 
    super(_intl, _changeDetectorRef );    
  }  

  ngOnInit() {
    this.length = DEFAULT_LENGTH;
    this.pageIndex = DEFAULT_PAGE_INDEX;
    this.pageSizeIndex = DEFAULT_PAGE_INDEX;
    this.pageSize = DEFAULT_PAGE_SIZE;
    this.pagesCount = DEFAULT_PAGE_INDEX;
    this.retrieveNewPage = false;
  }



  homePage() {
    this.pageIndex = 0;
    this.pageSizeIndex = 0;    
    this.retrieveNewPage = false;
    this.emitPage();   
  }

  getPageMaxIndex()
  {
    return (this.getMaxPageSize() / this.pageSize) - 1;
  }

  backPage() {   
    
    if (this.pageSize == this.getMaxPageSize() ||
        0 == this.pageSizeIndex) {
          if (this.pageIndex != 0) {
            this.pageSizeIndex = this.getPageMaxIndex();
          }
          this.pageIndexNumber = this.pageIndex;
          this.pageIndex = --this.pageIndexNumber;          
    }
    else {
      --this.pageSizeIndex;
    }
      
    this.pageIndex = (this.pageIndex < 0 ? 0 : this.pageIndex);
    this.pageSizeIndex = (this.pageSizeIndex < 0 ? 0 : this.pageSizeIndex);
  

    this.retrieveNewPage = false;
    this.emitPage();   
  }

  nextPage() {

    if (this.pageSize == this.getMaxPageSize() ||
      this.getPageMaxIndex() == this.pageSizeIndex) {
      this.pageIndexNumber = this.pageIndex;
      this.pageIndex = ++this.pageIndexNumber;
      this.pageSizeIndex = 0;
      this.addNewPage();
    }
    else {
      ++this.pageSizeIndex;
      this.retrieveNewPage = false;    
    }

    this.emitPage();   
  }

  endPage() {
    this.pageIndex = this.pagesCount; 
    this.pageSizeIndex = this.getPageMaxIndex();     
    this.retrieveNewPage = false; 
    this.emitPage();     
  }

  addNewPage()
  {
    if (this.pagesCount < this.pageIndex)
    {
      this.retrieveNewPage = true;
      ++this.pagesCount;
    }          
  }

  // when the pageSize changes the paginator must be recalculated.
  recalculatePaginator()
  {    
    this.pageSizeIndex = this.getPageMaxIndex();    
  }

  changePageSize()
  {
    this.recalculatePaginator();
    this.emitPage();
  }

  getMaxPageSize()
  {
    return this.pageSizeOptions.reduce(function(a, b) {
      return Math.max(a, b);
    }, 0);
  }

  getTotalPages()
  {    
    return ((this.pagesCount + 1) * (this.getPageMaxIndex() + 1));
  }

  getCurentPage()
  {    
    return ((this.getPageMaxIndex() * this.pageIndex) + 
            (this.pageIndex + (this.pageSizeIndex + 1)));
  }

  getTotalCurrentItems()
  {
    return (this.getMaxPageSize()) * (this.pagesCount + 1);
  }

  emitPage()
  {
    const pageEvent : CustomPaginatorEvent = new CustomPaginatorEvent();

    pageEvent.length = this.length;
    pageEvent.pageIndex = this.pageIndex;
    pageEvent.pageSizeIndex = this.pageSizeIndex;
    pageEvent.pageSize = this.pageSize;
    pageEvent.retrieveNewPage = this.retrieveNewPage;  
    pageEvent.maxPageSize = this.getMaxPageSize();
    
    this.page.emit(pageEvent);
  }

}
