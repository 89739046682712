import { environment } from 'src/environments/environment';
import { TableUsers } from './tableUsers';
import AES from 'crypto-js/aes';
import ENC_UTF8 from 'crypto-js/enc-utf8';
import JWT_DECODE from "jwt-decode";

export class TokenAdmin {
  token: string;
  encryptSecretKey = environment.encryptSecretKey;

  public getToken() {
    this.token = sessionStorage.getItem('token');
    return (this.token) ? this.decryptData(this.token) : undefined;
  }

  public setToken(token: string) {
    const ciphertext = AES.encrypt(JSON.stringify(token), this.encryptSecretKey).toString();
    sessionStorage.setItem('token', ciphertext);
    this.token = ciphertext;
  }

  decryptData(data) {

    try {
      const bytes = AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(ENC_UTF8));
      }
      return data;
    } catch (e) {
      //Error
    }
  }

  getPermission() {
    if (!sessionStorage.getItem('token')) { return null; }
    const token = this.decryptData(sessionStorage.getItem('token'));
    const tokenJson = JWT_DECODE(token);
    return tokenJson['permission'];
  }

  setUserLoggeIn(user: TableUsers) {
    const ciphertext = AES.encrypt(JSON.stringify(user), this.encryptSecretKey).toString();
    sessionStorage.setItem('psv-l', ciphertext);
  }

  getUserLoggeIn(): TableUsers {
    if (!sessionStorage.getItem('token')) { return null; }
    const token = this.decryptData(sessionStorage.getItem('token'));
    const tokenJson = JWT_DECODE(token);
    
    return tokenJson['user'];
  }
}
