import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { BcModalServiceService } from 'src/app/shared/services/commons/bc-modal-service/bc-modal-service.service';
import { LogOutService } from 'src/app/shared/services/logOut/log-out.service';

@Component({
  selector: 'app-modal-cambio-clave-ok',
  templateUrl: './modal-cambio-clave-ok.component.html',
  styleUrls: ['./modal-cambio-clave-ok.component.css']
})
export class ModalCambioClaveOkComponent implements OnInit {

  constructor(private bcModalServiceService: BcModalServiceService,
              private logOutService: LogOutService,
              private alertService: AlertService) { }

  ngOnInit() {
    //has no implementation
  }

  cerrar() {
    this.bcModalServiceService.close();
    this.logOutService.logOutTokenSession();
    this.alertService.success('Se ha identificado un cambio de contraseña, la sesión actual ha finalizado.')
  }

}
