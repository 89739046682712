import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services';

const TIMEOUT = 6000;

@Injectable({ providedIn: 'root' })
export class AlertService {

  public keepAfterNavigationChange = false;

  constructor(private router: Router, private dialogService: BcDialogService) {
    // clear alert message on route change
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        }
      }
    });
  }

  success(message: string, keepAfterNavigationChange = false) {

    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.showAlert('success', message);
  }

  error(errormessage: string, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.showAlert('error', errormessage);
  }

  showAlert(type: string, text: string, timeout: number=TIMEOUT) {
    this.dialogService.open(BcAlertComponent, {
      id: 'sales-room-alerts',
      type: type,
      title: text,
      insertBefore: true,
      timeout: timeout,
      elementRef: 'app-home-sales-room',
    });

  }
}
