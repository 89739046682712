import { NgModule, LOCALE_ID } from '@angular/core';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { BcInputModule } from '@bancolombia/design-system-web/bc-input';
import { BcRadioModule } from '@bancolombia/design-system-web/bc-radio';
import { BcCheckboxModule } from '@bancolombia/design-system-web/bc-checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RightPanelComponent } from 'src/app/shared/components/panel/right-panel/right-panel.component';
import { BcBackComponent } from './components/button/bc-back/bc-back.component';
import { BcDownloadButtonComponent } from './components/button/bc-download-button/bc-download-button.component';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BcLoadingComponent } from './components/bc-loading/bc-loading.component';
import { CardMenuComponent } from './components/card/card-menu/card-menu.component';
import { CheckListComponent } from './components/check-list/check-list.component';
import { EventEmitterComponent } from './components/event-emitter/event-emitter.component';
import { BcSearchInputComponent } from './components/input/bc-search-input/bc-search-input.component';
import { BcSearchInput2Component } from './components/input/bc-search-input2/bc-search-input2.component';
import { CustomBcInputFileComponent } from './components/input/custom-bc-input-file/custom-bc-input-file.component';
import { InputMultipleFilesComponent } from './components/input/input-multiple-files/input-multiple-files.component';
import { BcModalFilterStatusComponent } from './components/modal/bc-modal-filter-status/bc-modal-filter-status.component';
import { ModalActualizarSessionComponent } from './components/modal/modal-actualizar-session/modal-actualizar-session.component';
import { ModalCambioClaveOkComponent } from './components/modal/modal-cambio-clave-ok/modal-cambio-clave-ok.component';
import { ModalClaveActualComponent } from './components/modal/modal-clave-actual/modal-clave-actual.component';
import { ModalConfirmarClaveComponent } from './components/modal/modal-confirmar-clave/modal-confirmar-clave.component';
import { ModalTiempoInactividadComponent } from './components/modal/modal-tiempo-inactividad/modal-tiempo-inactividad.component';
import { LeftPanelComponent } from './components/panel/left-panel/left-panel.component';
import { NoveltyCardComponent } from './components/panel/novelty-history/novelty-card/novelty-card.component';
import { NoveltyHistoryComponent } from './components/panel/novelty-history/novelty-history.component';
import { GenericHistoryCardComponent } from './components/panel/right-panel/generic-history-card/generic-history-card.component';
import { ReadOnlyDataComponent } from './components/read-only-data/read-only-data.component';
import { BcStatusFilterInlineComponent } from './components/status/status-filter/bc-status-filter-inline/bc-status-filter-inline.component';
import { StatusTagComponent } from './components/status/status-tag/status-tag.component';
import { TooltipHtmlComponent } from './components/tooltip-html/tooltip-html.component';
import { BcModalComponent } from './components/modal/bc-modal/bc-modal/bc-modal.component';
import { RequestClientInfoComponent } from './components/request-client-info/request-client-info.component';
import { HtmlDecodePipe } from './pipes/html-decode/html-decode.pipe';
import { FilterPipe } from './pipes/filter/filter.pipe';
import { FilterLogPipe } from './pipes/filter/filterLog.pipe';
import { RouterModule } from '@angular/router';
import { SectionTitleComponent } from './components/section-title/section-title.component';
import { CapitalHeaderComponent } from './components/capital-header/capital-header.component';
import { CapitalFooterComponent } from './components/capital-footer/capital-footer.component';
import { CoreModule } from '../core/core.module';
import { CapitalUserMenuComponent } from './components/capital-user-menu/capital-user-menu.component';
import { NotificationComponent } from './components/notification/notification.component';
import { BcButtonModule } from '@bancolombia/design-system-web/bc-button';
import { StateBubbleComponent } from './components/state-bubble/state-bubble.component';
import { NotificationRequestComponent } from './components/notification-request/notification-request.component';
import { CustomDatePipe } from './pipes/date/custom.datepipe';
import localEsCo from '@angular/common/locales/es-CO';
import { BcPopOverModule } from '@bancolombia/design-system-web/bc-pop-over';
import { BcIconButtonModule } from '@bancolombia/design-system-web/bc-icon-button';
import { BcStatusModule } from '@bancolombia/design-system-web/bc-status';

registerLocaleData(localEsCo, 'es-Co')
import { HttpClientModule } from "@angular/common/http";
import { FiltersBackofficeComponent } from './components/filters-backoffice/filters-backoffice.component';
import { PaginatedSelectorComponent } from './components/paginated-selector/paginated-selector.component';
import { MatSelectModule } from '@angular/material/select';
import { ModalCustomMessageComponent } from './components/modal/modal-custom-message/modal-custom-message.component';
import { BcTooltipModule } from '@bancolombia/design-system-web/bc-tooltip';
import { ModalEliminarUnidadInmobiliariaComponent } from './components/modal-eliminar-unidad-inmobiliaria/modal-eliminar-unidad-inmobiliaria.component';
import { SelectFilterComponent } from './components/select-filter-fideicomiso/select-filter-fideicomiso.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { StatusNotificationComponent } from './components/status-notification/status-notification.component';


const SDB_COMPONENTS = [
  BcIconModule.forRoot({ path: 'https://library-sdb.apps.bancolombia.com/bds/6.25.3/' }),
  BcCheckboxModule,
  BcInputModule,
  BcRadioModule,
  BcButtonModule,
  BcTooltipModule,
  BcPopOverModule,
  BcIconButtonModule,
  BcStatusModule
];

const MATERIAL_COMPONENTS = [
  MatChipsModule,
  MatInputModule,
  MatAutocompleteModule,
  MatOptionModule,
  MatTooltipModule,
  MatSelectModule,
];

@NgModule({
  declarations: [
    RightPanelComponent,
    BcBackComponent,
    BcLoadingComponent,
    BcDownloadButtonComponent,
    CardMenuComponent,
    CheckListComponent,
    EventEmitterComponent,
    BcSearchInput2Component,
    CustomBcInputFileComponent,
    InputMultipleFilesComponent,
    BcModalFilterStatusComponent,
    BcModalComponent,
    ModalActualizarSessionComponent,
    ModalCambioClaveOkComponent,
    ModalClaveActualComponent,
    ModalConfirmarClaveComponent,
    ModalTiempoInactividadComponent,
    LeftPanelComponent,
    NoveltyCardComponent,
    NoveltyHistoryComponent,
    GenericHistoryCardComponent,
    ReadOnlyDataComponent,
    BcStatusFilterInlineComponent,
    StatusTagComponent,
    TooltipHtmlComponent,
    BcSearchInputComponent,
    RequestClientInfoComponent,
    FiltersBackofficeComponent,
    PaginatedSelectorComponent,
    // pipes
    HtmlDecodePipe,
    FilterPipe,
    FilterLogPipe,
    CustomDatePipe,

    SectionTitleComponent,
    CapitalHeaderComponent,
    CapitalFooterComponent,
    CapitalUserMenuComponent,
    NotificationComponent,
    StateBubbleComponent,
    NotificationRequestComponent,
    ModalCustomMessageComponent,
    ModalEliminarUnidadInmobiliariaComponent,
    SelectFilterComponent,
    NotificationListComponent,
    StatusNotificationComponent
  ],
  imports: [
    SDB_COMPONENTS,
    MATERIAL_COMPONENTS,
    CommonModule,
    FormsModule,
    ReactiveFormsModule.withConfig({callSetDisabledState: 'whenDisabledForLegacyCode',}),
    RouterModule,
    CoreModule,
    NgxMatSelectSearchModule,
    HttpClientModule,
  ],
  providers: [ { provide: LOCALE_ID, useValue: 'es-Co' } ],
  exports: [
    RightPanelComponent,
    BcBackComponent,
    BcLoadingComponent,
    BcDownloadButtonComponent,
    CardMenuComponent,
    CheckListComponent,
    EventEmitterComponent,
    BcSearchInputComponent,
    BcSearchInput2Component,
    CustomBcInputFileComponent,
    InputMultipleFilesComponent,
    BcModalFilterStatusComponent,
    BcModalComponent,
    ModalActualizarSessionComponent,
    ModalCambioClaveOkComponent,
    ModalClaveActualComponent,
    ModalConfirmarClaveComponent,
    ModalTiempoInactividadComponent,
    LeftPanelComponent,
    NoveltyCardComponent,
    NoveltyHistoryComponent,
    GenericHistoryCardComponent,
    ReadOnlyDataComponent,
    BcStatusFilterInlineComponent,
    StatusTagComponent,
    TooltipHtmlComponent,
    RequestClientInfoComponent,
    SelectFilterComponent,
    FiltersBackofficeComponent,
    PaginatedSelectorComponent,
    // pipes
    HtmlDecodePipe,
    FilterPipe,
    FilterLogPipe,
    CustomDatePipe,

    SectionTitleComponent,
    CapitalHeaderComponent,
    CapitalFooterComponent,
    CapitalUserMenuComponent,
    NotificationComponent,
    StateBubbleComponent,
    NotificationListComponent,
    StatusNotificationComponent

  ]
})
export class SharedModule { }
