<main>
  <div id="footer-container">
    <div class="d-flex justify-content-between">
      <div class="d-flex flex-column bd-highlight mb-3">
        <div>
          <img id="bancolombia-logo" src="/assets/images/bancolombia-logo.svg" alt="logo Bancolombia">
        </div>
        <div id="copyright">Copyright © 2020 Grupo Bancolombia.</div>
        <div>
          <img id="vigilado" src="/assets/images/vigilado-super.svg"
            alt="vigilado superintendencia financiera de colombia">
        </div>
      </div>
      <div class="d-flex flex-column bd-highlight mb-3">
        <div class="right-text">Dirección IP {{ip}}</div>
        <div class="right-text">Última visita {{todayDate | customDate}}</div>
      </div>
    </div>
  </div>
</main>