import { Component, OnInit, Injector } from "@angular/core";
import { Validators, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { first } from "rxjs/operators";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { ChangePassword } from "src/app/shared/models/changePassword";
import { Login } from "src/app/shared/models/login";
import { TokenAdmin } from "src/app/shared/models/TokenAdmin";
import { ResetPasswordService } from "src/app/shared/services/resetPassword/reset-password.service";
import { AlertService } from "src/app/shared/services/alert/alert.service";
import { ParamService } from "src/app/shared/services/param/param.service";
import { LoginService } from "src/app/shared/services/login/login.service";
import { BcLoadingService } from "src/app/shared/services/commons/bc-loading/bc-loading.service";
import { PermissionService } from "src/app/shared/services/permission/permission.service";
import { LogOutService } from "src/app/shared/services/logOut/log-out.service";
import { ForgotPasswordService } from "src/app/shared/services/forgotPassword/forgot-password.service";
import { ResponseService } from "src/app/shared/models/response-service";

@Component({
  selector: "app-new-password",
  templateUrl: "./new-password.component.html",
  styleUrls: ["./new-password.component.css"],
})
export class NewPasswordComponent implements OnInit {
  newPasswordForm: UntypedFormGroup;
  changePassword: ChangePassword;
  loginInfo: Login;
  private tokenAdmin: TokenAdmin = new TokenAdmin();
  submitted = false;
  email: string;
  securityCode: string;
  captchaEnable:any = JSON.parse(environment.captchaEnabled);

  private formBuilder: UntypedFormBuilder;
  public resetPasswordService: ResetPasswordService;
  public alertService: AlertService;
  public paramService: ParamService;
  public loginService: LoginService;
  private bcLoadingService: BcLoadingService;
  public permissionService: PermissionService;
  private logOutService: LogOutService;
  public forgotPasswordService: ForgotPasswordService;
  public router: Router;

  constructor(
    private injector : Injector
  ) {
    this.formBuilder = injector.get<UntypedFormBuilder>(UntypedFormBuilder);
    this.resetPasswordService = injector.get<ResetPasswordService>(ResetPasswordService);
    this.alertService = injector.get<AlertService>(AlertService);
    this.paramService = injector.get<ParamService>(ParamService);
    this.loginService = injector.get<LoginService>(LoginService);
    this.bcLoadingService = injector.get<BcLoadingService>(BcLoadingService);
    this.permissionService = injector.get<PermissionService>(PermissionService);
    this.logOutService = injector.get<LogOutService>(LogOutService);
    this.forgotPasswordService = injector.get<ForgotPasswordService>(ForgotPasswordService);
    this.router = injector.get<Router>(Router);
  }

  get f() {
    return this.newPasswordForm.controls;
  }

  ngOnInit() {
    this.newPasswordForm = this.formBuilder.group({
      NewPass: ["", Validators.required],
      NewPassOk: ["", Validators.required],
      captcha: [],
    });

    this.email = this.paramService.getEmail();
    this.securityCode = this.paramService.getCodeSecurity();

    if (!this.email || !this.securityCode) {
      this.router.navigate(["/login"]);
    }
  }

  private validateLogin(data: ResponseService) {
    this.bcLoadingService.close();
    switch (data.codeReturn) {
      case "1": {
        //login sucess
        this.permissionService.guardarToken(data.token);
        this.router.navigate([this.tokenAdmin.getPermission()["home"]]);
        this.logOutService.startTimer();
        break;
      }
      case "2": {
        this.permissionService.guardarToken(data.token);
        this.paramService.setEmail(this.email);
        this.paramService.setPassword(this.loginInfo.password);
        this.logOutService.logOutBackground();
        //Cambio de Contraseña;
        this.router.navigate(["/confirmnewpassword"]);
        break;
      }
      default: {
        //Error;
        this.alertService.error(data.message);
        break;
      }
    }
  }

  loginRequest(loginInfo: Login) {
    this.loginService
      .login(loginInfo)
      .pipe(first())
      .subscribe(
        (data) => {
          this.validateLogin(data);
        },
        (_) => {
          this.bcLoadingService.close();
          this.alertService.error(
            `¡Lo sentimos! No ha sido posible iniciar sesión. El Link Sala de Ventas solamente está disponible
            de 5:00 a.m. a 9:00 p.m.`
          );
        }
      );
  }

  login() {
    this.loginInfo = new Login();
    this.loginInfo.emailAddr = this.paramService.getEmail();
    this.loginInfo.password = this.changePassword.newPassword;
    this.loginInfo.captcha = this.f.captcha.value;
    this.loginRequest(this.loginInfo);
  }

  resetPassword() {
    this.changePassword = new ChangePassword();
    this.changePassword.emailAddr = this.email;
    this.changePassword.resetCode = this.securityCode;
    this.changePassword.newPassword = this.newPasswordForm.get("NewPass").value;
    this.changePassword.verifyPassword =
      this.newPasswordForm.get("NewPassOk").value;

    this.bcLoadingService.show();
    this.resetPasswordService
      .resetPassword(this.changePassword)
      .pipe(first())
      .subscribe(
        async (data) => {
          if (data.codeReturn !== "-1") {
            this.login();
            this.alertService.success("Se cambió la contraseña correctamente");
          } else {
            this.bcLoadingService.close();
            this.alertService.error(data.message);
          }
        },
        (error) => {
          this.bcLoadingService.close();
          this.alertService.error(
            "¡Lo sentimos! No ha sido posible enviar la solicitud por una falla del sistema. Por favor vuelva a intentarlo mas tarde."
          );
        }
      );
  }

  resolved(captchaResponse: string) {
    this.f.captcha.setValue(captchaResponse);
    this.login();
  }

  captchaIsValid() {
    return this.f.captcha.value || !this.captchaEnable;
  }
}
