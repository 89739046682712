import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';
import { TokenAdmin } from '../../models/TokenAdmin';
import { ChangePassword } from '../../models/changePassword';
import { ResponseService } from '../../models/response-service';

@Injectable({
  providedIn: 'root'
})

export class ResetPasswordService {
  private urlEndPoint = URL_SERVICIOS;
  private serviceResetPassword = 'resetpassword';
  private tokenAdmin: TokenAdmin = new TokenAdmin();
  private token = this.tokenAdmin.getToken();

  private httpHeaders = new HttpHeaders({'Content-Type' : 'application/json'});

  constructor( private http: HttpClient ) { }

  resetPassword( changePassword: ChangePassword ) {
    return this.http.post<ResponseService>(
      `${this.urlEndPoint}/${this.serviceResetPassword}`,
      changePassword,
      {headers: this.httpHeaders});
  }
}
