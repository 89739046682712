
import { environment } from 'src/environments/environment';
export const DocumentsDetailsConstants= {
   SIZE_HEADER_ZERO : 0,
   SIZE_HEADER_ONE : 1,
   SIZE_HEADER_TWO : 2,
   SIZE_HEADER_THREE : 3,
   SIZE_HEADER_FOUR : 4,
   SIZE_HEADER_FIVE : 5,
   SIZE_HEADER_SIX : 6,
   SIZE_HEADER_SEVEN : 7,
   LINK_FC_PERSONA_NATURAL : environment.linkPersonaNatural,
   LINK_FC_PERSONA_JURIDICA : environment.linkPersonaJuridica,
   LINK_SOCIOS_ACCIONISTAS : environment.linkSociosAccionistas,
};
