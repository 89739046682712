<main>
  <div class="d-flex justify-content-between">
    <div>
      <button type="button"
              name="btnBack" bc-button-icon aria-label="button-select" 
              (click)="actionBtnBack()" *ngIf="isBtnBack" class="custom-button">
        <bc-icon [style.color]="'#FFFFFF'" aria-hidden="true" aria-label="icon-back">
          arrow2-left</bc-icon>
      </button>
      <img id="logo" src="/assets/images/bancolombia-capital-logo.svg" alt="bancolombia capital logo">
    </div>
    <shared-capital-user-menu></shared-capital-user-menu>
  </div>
</main>
