import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BcModalServiceService {
  private subject = new Subject<any>();
  constructor() {
    //has no implementation
   }

  get modal(): Observable<any> {
    return this.subject.asObservable();
  }

  show(component:any) {
    this.subject.next({action: 'show', component});
  }

  showWithData(component:any,data:any){
    this.subject.next({action: 'show', component,data});
  }

  showWithDataHideClose(component:any,data:any){
    this.subject.next({action: 'show', component, data, showClose: false});
  }

  showWithDataAndFullSize(component:any,data:any){
    this.subject.next({action: 'show',isFullSize:true, component,data});
  }

  close() {
    this.subject.next({action: 'close'});
  }

}
