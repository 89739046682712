<div id="menu-container" class="user-menu-container">
  <a class="nav-link dropdown-toggle cum-link"
    (click)="goToListNews()"
    *ngIf="isNotificationRol"
    [ngClass]="{'rounded-button': isAdvisorPublicationRoute()}">
    <div class="white-text cum-menu-display">
      <em class="bc-icon bc-sm" aria-hidden="true" aria-label="help" role="img">help</em>
      <span class="white-text cum-menu-text">Ayuda</span>
    </div>
  </a>

  <a class="nav-link dropdown-toggle target cum-link" aria-controls="idPrueba" (click)="toogle()" *ngIf="isNotificationRol">
    <div class="white-text cum-menu-display">
      <em class="bc-icon bc-sm" aria-hidden="true" aria-label="notification" role="img">notification</em>
      <span class="white-text cum-menu-text">Notificaciones</span>
    </div>
  </a>
  <div class="custom-popover" *ngIf="isNotificationRol">
    <bc-pop-over *ngIf="isnotification"  width="400" height="auto" scroll="true"  #idPrueba componentId="idPrueba" >
      <div class="cum-container-header-pop-over">
        <div class="cum-left cum-align-item">Notificaciones ({{listNotification.length}})</div>
        <div class="cum-right cum-close-link" (click)="toogle()">
          Cerrar
        </div>
      </div>
      <div class="cum-container-body-pop-over" >
        <div class="cum-news">
          <span>{{ listNotification?.length > 0 ? 'Nuevas' : 'No hay notificaciones' }}</span>
        </div>
        <div class="scrollable-notifications">
          <app-notification-list [listNotification]="listNotification"></app-notification-list>
        </div>
      </div>
    </bc-pop-over>
  </div>
  <div class="cum-vertical-line cum-link"></div>
  <a class="nav-link dropdown-toggle cum-link" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
    aria-expanded="false">
    <div class="cum-link-user-container">
      <div class="cum-link-user-left">
        <bc-icon class="icon-user" size="md" aria-hidden="true" aria-label="chevron-down">
          user-circle
        </bc-icon>
      </div>
      <div class="cum-link-user-center">
        <span id="name" class="white-text cum-link-user-center-user">{{userName}}</span>
        <br>
        <span id="role" class="white-text cum-link-user-center-role">{{role}}</span>
      </div>
      <div class="cum-link-user-right">
        <bc-icon id="arrow" class="white-text" size="xs" aria-hidden="true" aria-label="arrow2-down">
          arrow2-down
        </bc-icon>
      </div>
    </div>
  </a>

  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
    <a class="dropdown-item" *ngIf="!isAdp()" (click)="changePassword()">Cambiar contraseña</a>
    <a class="dropdown-item" (click)="exit()">Cerrar sesión</a>
  </div>
</div>
