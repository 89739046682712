<body class="body">
    <app-header></app-header>
    <div class="btn_back_container">
        <div (click)="goBack()">
            <bc-icon size="xs" aria-hidden="true" aria-label="Atrás">
                arrow2-left
            </bc-icon>
            <span class="button-back">Atrás</span>
        </div>
    </div>
    <section>
        <article class="article">
            <h1 class="titulo-gestion-contrasena">¿Olvidaste la contraseña?</h1>
            <h5 class="txt-subtitle-forgot-pass">
                En tu correo encontrarás un código de para que puedas crear tu nueva contraseña.</h5>
            <form [formGroup]="codigoForm" autocomplete="off">
                <div class="container d-flex justify-content-center h-100 container-form" >
                    <div >
                        <div  class="card custom-card">
                            <div class="card-body">
                                <div class="text-center">
                                    <bc-icon size="sm" aria-hidden="true" aria-label="prueba">
                                        lock
                                    </bc-icon>
                                    <p class="label-password">Ingrese el código</p>
                                </div>
                                <div  class="margin-custom group">
                                    <bc-form-field class="margin-custom">
                                        <bc-input-token [num-inputs]="6" (validated)="onValidToken($event)" 
                                            (changeValue)="changeValue($event)">
                                        </bc-input-token>
                                    </bc-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="form-group margin-top text-center" >
                            <button type="button" bc-button sizeButton="Small" 
                            id="code_btn" [disabled]="codigoForm.invalid || !validToken" 
                            (click)="validateCode()">CONTINUAR</button>
                        </div>
                    </div>
                </div>
            </form>
        </article>
    </section>

    <app-footer></app-footer>
</body>