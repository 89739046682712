import { Component, OnInit, Injector } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalConfirmarClaveComponent } from '../modal-confirmar-clave/modal-confirmar-clave.component';
import { first } from 'rxjs/operators';
import { Login } from 'src/app/shared/models/login';
import { BcModalServiceService } from 'src/app/shared/services/commons/bc-modal-service/bc-modal-service.service';
import { LoginService } from 'src/app/shared/services/login/login.service';
import { ParamService } from 'src/app/shared/services/param/param.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { PermissionService } from 'src/app/shared/services/permission/permission.service';
import { BcLoadingService } from 'src/app/shared/services/commons/bc-loading/bc-loading.service';

@Component({
  selector: 'app-modal-clave-actual',
  templateUrl: './modal-clave-actual.component.html',
  styleUrls: ['./modal-clave-actual.component.css']
})
export class ModalClaveActualComponent implements OnInit {

  email: string;
  passwordForm: UntypedFormGroup;
  loginInfo: Login;
  mess: string[];
  loading: boolean;


  private formBuilder: UntypedFormBuilder;
  private bcModalServiceService: BcModalServiceService;
  public loginService: LoginService;
  public paramService: ParamService;
  public alertService: AlertService;
  public permissionService: PermissionService;
  private bcLoadingService: BcLoadingService;

  constructor(
    private injector : Injector
  ) {
    this.formBuilder = injector.get<UntypedFormBuilder>(UntypedFormBuilder);
    this.bcModalServiceService = injector.get<BcModalServiceService>(BcModalServiceService);
    this.loginService = injector.get<LoginService>(LoginService);
    this.paramService = injector.get<ParamService>(ParamService);
    this.alertService = injector.get<AlertService>(AlertService);
    this.permissionService = injector.get<PermissionService>(PermissionService);
    this.bcLoadingService = injector.get<BcLoadingService>(BcLoadingService);
  }

  get f() { return this.passwordForm.controls; }

  ngOnInit() {
    this.email = this.paramService.getEmail();
    this.passwordForm = this.formBuilder.group({
      password: ['', Validators.required]
    });
  }

  disableButton() {
    return  (this.f.password.value.length === 0 );
   }

  login() {
    if (this.disableButton()) { return; }

    this.bcLoadingService.show();

    this.loginInfo = new Login();
    this.loginInfo.emailAddr = this.email;
    this.loginInfo.password = this.f.password.value;

    this.paramService.setEmail(this.loginInfo.emailAddr);
    this.paramService.setPassword(this.loginInfo.password);

    this.loginService.loginValidar(this.loginInfo)
    .pipe(first())
    .subscribe(
        data => {
          this.bcLoadingService.close();
          switch (data.codeReturn) {
            case '1': {
              // login sucess
              this.loading = false;
              this.bcModalServiceService.show( ModalConfirmarClaveComponent );
              break;
            }
            case '2': {
               // Cambio de Contraseña;
               this.bcModalServiceService.show( ModalConfirmarClaveComponent );
               break;
            }
            default: {
               // Error;
               this.alertService.error(data.message);
               break;

            }
          }
        },
        error => {
          this.mess =  [error.message];
          this.bcLoadingService.close();
          this.alertService.error('¡Lo sentimos! No ha sido posible enviar la solicitud por una falla del sistema. Por favor vuelva a intentarlo mas tarde.');
        });

    
  }

}
