import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalCambioClaveOkComponent } from '../modal-cambio-clave-ok/modal-cambio-clave-ok.component';
import { first } from 'rxjs/operators';
import { ChangePassword } from 'src/app/shared/models/changePassword';
import { BcModalServiceService } from 'src/app/shared/services/commons/bc-modal-service/bc-modal-service.service';
import { ChangePasswordService } from 'src/app/shared/services/changePassword/change-password.service';
import { ParamService } from 'src/app/shared/services/param/param.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { BcLoadingService } from 'src/app/shared/services/commons/bc-loading/bc-loading.service';

@Component({
  selector: 'app-modal-confirmar-clave',
  templateUrl: './modal-confirmar-clave.component.html',
  styleUrls: ['./modal-confirmar-clave.component.css']
})
export class ModalConfirmarClaveComponent implements OnInit {

  passwordForm: UntypedFormGroup;
  changePasswordInfo: ChangePassword;
  email: string;

  constructor( private formBuilder: UntypedFormBuilder,
               private bcModalServiceService: BcModalServiceService,
               public changePasswordService: ChangePasswordService,
               public paramService: ParamService,
               public alertService: AlertService,
               private bcLoadingService: BcLoadingService, ) { }

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      passwordNew: ['', Validators.required],
      passwordConfirm: ['', Validators.required],
    });
  }

  get f() { return this.passwordForm.controls; }

  disableButton() {
    return  (this.f.passwordNew.value.length === 0 || this.f.passwordConfirm.value.length === 0 );
   }

   changePassword() {
    if (this.disableButton()) { return; }

    if (!this.validarClaves() ) { return; }

    this.bcLoadingService.show();

    this.changePasswordInfo = new ChangePassword();
    this.changePasswordInfo.emailAddr = this.paramService.getEmail();
    this.changePasswordInfo.oldPassword = this.paramService.getPassword();
    this.changePasswordInfo.newPassword = this.passwordForm.get('passwordNew').value;
    this.changePasswordInfo.verifyPassword = this.passwordForm.get('passwordConfirm').value;

    this.changePasswordService.changePasswordLogin( this.changePasswordInfo )
        .pipe(first())
        .subscribe(
            data => {
              this.bcLoadingService.close();

                if (data.codeReturn !== '-1') {
                  this.bcModalServiceService.show( ModalCambioClaveOkComponent );

                } else {
                  this.alertService.error(data.message);
                }
            },
            error => {
              this.bcLoadingService.close();
             }
        );
   }

   /**
    * Se encarga de verificar si el formulario ya tiene las dos claves digitadas
    */
   private validarClaves() {
    if ( this.f.passwordNew.value.length === this.f.passwordConfirm.value.length) {
      if (this.f.passwordNew.value === this.f.passwordConfirm.value) {
        return true;
      }
    }
    this.alertService.error('Las claves no coinciden.');
    return false;
   }

}
