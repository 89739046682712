import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Login } from 'src/app/shared/models/login';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { ParamService } from 'src/app/shared/services/param/param.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  loginInfo: Login;
  public email: string;
  loginForm: UntypedFormGroup;
  submitted = false;
  loading = false;
  isSendEmail: boolean;

  constructor(private formBuilder: UntypedFormBuilder,
              public router: Router,
              private alertService: AlertService,
              public paramService: ParamService,
  ) {
  }

  ngOnInit() {
    sessionStorage.clear();
    this.isSendEmail = this.paramService.getIsSendEmail();
    this.email = this.paramService.getEmail() || '';
    this.loginForm = this.formBuilder.group({
      email: [this.email, Validators.required]
    });
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.loading = true;
    this.submitted = true;
    if (this.loginForm.invalid) {
      this.loading = false;
    } else {
      this.paramService.setEmail(this.loginForm.get('email').value);
      this.router.navigate(['/password']);
    }
  }

}
