import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserMenuCommunicationService {
  private toggleSource = new Subject<void>();
  private temporaryVariable: any; // Aquí almacenaremos la variable temporal

  toggleNotification() {
    this.toggleSource.next();
  }

  getToggleNotificationObservable() {
    return this.toggleSource.asObservable();
  }

  setTemporaryVariable(value: any) {
    this.temporaryVariable = value;
  }

  getTemporaryVariable() {
    return this.temporaryVariable;
  }
}
