import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { LogResponse } from "src/app/shared/models/logResponse";
import { LogService } from "src/app/shared/services/log/log.service";
import { BcModalServiceService } from "src/app/shared/services/commons/bc-modal-service/bc-modal-service.service";
import { BcLoadingService } from "src/app/shared/services/commons/bc-loading/bc-loading.service";
import { SearchTextService } from "src/app/shared/services/commons/search-text/search-text.service";
import { ModalExportarLogComponent } from "src/app/modules/backoffice/components/modal-exportar-log/modal-exportar-log.component";
import { DocumentsDetailsComponent } from "src/app/modules/consult-users/components/responses/documents-details/documents-details.component";
import { CustomPaginatorEvent } from "src/app/shared/models/custom-paginator-event";
import { CustomPaginatorComponent } from "../custom-paginator/custom-paginator.component";

const defaultPageIndex: number = 0;
const defaultPageSize: number = 20;

@Component({
  selector: "app-log",
  templateUrl: "./log.component.html",
  styleUrls: ["./log.component.css"],
})
export class LogComponent implements OnInit {
  overallLength: number = defaultPageSize;
  finalPageIndex: number = 0;

  currentLength: number = 0;
  currentPageIndex: number = 0;
  currentPageSize: number = 0;

  displayedColumns: string[] = [
    "documentType",
    "documentNumber",
    "client",
    "businessCode",
    "queryDate",
    "controlList",
    "mdm",
  ];
  filterBy: string[] = [
    "documentNumber",
    "client",
    "businessCode",
    "businessName",
    "queryDate",
    "controlListText",
    "mdmText",
  ];
  dateFields: string[] = ["queryDate"];
  dataSource: any;
  dataSourceIndex: any[] = [];
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  @ViewChild(CustomPaginatorComponent)
  customPaginator: CustomPaginatorComponent;

  datosLog: LogResponse[] = [];
  error = false;

  searchTextFilter: string;

  constructor(
    private logService: LogService,
    private bcModalServiceService: BcModalServiceService,
    private bcLoadingService: BcLoadingService,
    private searchTextService: SearchTextService
  ) {
    this.dataSource = new MatTableDataSource();
    this.dataSource.sort = this.sort;
  }

  ngOnInit() {
    this.searchTextService.filterByFields = this.filterBy;
    this.searchTextService.dateFields = this.dateFields;
    this.bcLoadingService.show();

    this.currentPageIndex = defaultPageIndex;
    this.currentPageSize = defaultPageSize;
    this.currentLength = this.currentPageSize;

    this.logService.getLog(defaultPageIndex, defaultPageSize).subscribe(
      (data) => {
        this.datosLog = data;
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sort = this.sort;
        this.dataSourceIndex.push(this.dataSource);

        this.dataSource.paginator = this.customPaginator;
        this.bcLoadingService.close();
      },
      (error) => {
        this.bcLoadingService.close();
        this.error = true;
      }
    );
  }

  descargarArchivoLog() {
    this.bcModalServiceService.show(ModalExportarLogComponent);
  }

  @Input() set filterByText(filterText: string) {
    this.searchTextFilter = filterText;
    if (this.searchTextFilter != undefined) {
      this.filtrar(this.searchTextFilter);
    }
  }

  filtrar(textSearch: string) {
    this.dataSource.data = this.searchTextService.filtrar(
      this.datosLog,
      textSearch
    );
  }

  eventMdm(log: LogResponse) {
    if (log.mdm != "ERROR" && log.mdm != "EXISTE_ACTUALIZADO") {
      this.bcModalServiceService.showWithDataAndFullSize(
        DocumentsDetailsComponent,
        log
      );
    }
  }

  calculateFinalDataSource(event: CustomPaginatorEvent) {
    const rangeDataSource = new MatTableDataSource();
    const offset =
      event.maxPageSize * event.pageIndex +
      event.pageSize * event.pageSizeIndex;
    for (let i = 0; i < event.pageSize; i++) {
      const j = offset + i;
      if (this.datosLog[j]) {
        rangeDataSource.data.push(this.datosLog[j]);
      }
      rangeDataSource.sort = this.sort;
    }
    return rangeDataSource;
  }

  onPaginateChange(event: CustomPaginatorEvent) {
    if (event.retrieveNewPage) {
      this.bcLoadingService.show();
      this.logService
        .getLog(event.pageIndex, event.pageSize)
        .subscribe((data) => {
          this.datosLog = this.datosLog.concat(data);
          this.dataSource = this.calculateFinalDataSource(event);
          this.bcLoadingService.close();
        });
    } else {
      this.dataSource = this.calculateFinalDataSource(event);
    }
  }
}
