import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { BcLoadingService } from 'src/app/shared/services/commons/bc-loading/bc-loading.service';
import { BcModalServiceService } from 'src/app/shared/services/commons/bc-modal-service/bc-modal-service.service';
import { DownloadLogService } from 'src/app/shared/services/download-log/download-log.service';

@Component({
  selector: 'app-modal-exportar-log',
  templateUrl: './modal-exportar-log.component.html',
  styleUrls: ['./modal-exportar-log.component.css']
})
export class ModalExportarLogComponent implements OnInit {
  blob: any;
  downloadLogForm: UntypedFormGroup;
  constructor(private formBuilder: UntypedFormBuilder,private downloadLogService: DownloadLogService,
    private bcLoadingService:BcLoadingService,
    private bcModalServiceService:BcModalServiceService,) { }

  ngOnInit() {

    this.downloadLogForm = this.formBuilder.group({
      fechaInicial: ['', Validators.required],
      fechaFinal: ['', Validators.required],
    });
  }

  disableButton(){
    return this.downloadLogForm.invalid||this.fechaFinalisInValid();
  }

  fechaFinalisInValid(){
    const d1 = new Date(this.downloadLogForm.controls.fechaFinal.value); 
    const d2 = new Date(this.downloadLogForm.controls.fechaInicial.value);
    return (d1<d2);
  }

  descargarLog() {
    this.bcLoadingService.show("descargando");
    this.downloadLogService.getLog(
      this.downloadLogForm.controls.fechaInicial.value,
      this.downloadLogForm.controls.fechaFinal.value)
    .subscribe((data: Blob) => {
      
      this.blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = 'logs.xlsx';
      this.bcLoadingService.close();
      this.bcModalServiceService.close();
      link.click();
    }
    ,error=>{
      this.bcLoadingService.close();
    });

  }

}
