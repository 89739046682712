export class ClientValidateResponse {
    documentType: string;
    documentNumber: string;
    firstName: string;
    personType: string;
    controlListStatus: string;
    customerManagerStatus: string;
    isHighlyRiskCustomer: boolean;
    activity?:string;
    fullName?: string;
    noveltyId?:number;
}
