import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-bc-download-button',
  templateUrl: './bc-download-button.component.html',
  styleUrls: ['./bc-download-button.component.css']
})
export class BcDownloadButtonComponent  {

  @Input() textToShow:string="Descargar";

}
