<div>
    <img id="message-icon" src="/assets/images/alert-yellow.svg" alt="alert icon">

    <h2 id="message-title">¡Espera un momento!</h2>

    <p *ngIf="isMessageMigration === true" id="message-description">
        Debido a un mantenimiento que le estamos
        realizando a la herramienta, es necesario que
        cambies tu contraseña para poder ingresar.
        <br><br>
        La nueva contraseña debe ser de mínimo 10 
        caracteres y contar con 3 de los siguientes 
        tipos de caracteres: una letra minúscula, una 
        letra mayúscula, un dígito o un carácter especial
    </p>
    
    <p *ngIf="isMessageMigration === false" id="message-description">
        La nueva contraseña debe ser de mínimo 10 
        caracteres y contar con 3 de los siguientes 
        tipos de caracteres: una letra minúscula, una 
        letra mayúscula, un dígito o un carácter especial
    </p>

    <button bc-button id="change-password-button" type="button" (click)="changePassword()">
        CAMBIAR CONTRASEÑA
    </button>
</div>