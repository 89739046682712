<main>
  <div>
    <br>
    <h3>Cambio realizado con éxito</h3>
    <p class="bc-span-info">Recibirás un correo de confirmación del cambio</p>

    <img class="" alt="Cambio Ok" src="../../../assets/images/CambioClaveOk.png">
    <br>
  </div>
</main>
<br>
<br>
<div id="button-container" class="center">
  <button type="button" bc-button type="submit" (click)="cerrar()">ACEPTAR</button>
</div>