<main>
  <form [formGroup]="passwordForm" #captchaProtectedForm="ngForm" autocomplete="off">
    <bc-icon size="sm" aria-hidden="true" aria-label="prueba">
      lock-alt
    </bc-icon>
    <h3>Cambiar la contraseña</h3>
    <p class="bc-span-info">Ingresa la contraseña actual.</p>
    <bc-form-field>
      <input bc-input typeInput="input" value="" formControlName="password" type="password" required autofocus />
      <span></span>
    </bc-form-field>
    <button bc-button type="submit" (click)="login()" [disabled]="disableButton()">CONTINUAR</button>
  </form>
</main>