import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/services/login/login.service';
import { BcLoadingService } from 'src/app/shared/services/commons/bc-loading/bc-loading.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { PermissionService } from 'src/app/shared/services/permission/permission.service';
import { LogOutService } from 'src/app/shared/services/logOut/log-out.service';
import { TokenAdmin } from 'src/app/shared/models/TokenAdmin';

@Component({
  selector: 'app-adp',
  templateUrl: './adp.component.html',
  styleUrls: ['./adp.component.css']
})
export class AdpComponent implements OnInit {

  private tokenAdmin: TokenAdmin = new TokenAdmin();

  errorMsg:string;


  constructor(private authService: MsalService,
    private loginService:LoginService,
    private bcLoadingService: BcLoadingService,
    private alertService: AlertService,
    private permissionService: PermissionService,
    private logOutService: LogOutService,
    private router: Router,) {

    }

  ngOnInit() {
    this.bcLoadingService.show();
    this.errorMsg = sessionStorage.getItem("msal.login.error");
    if(this.errorMsg){
      this.bcLoadingService.close();
    }else if(!this.errorMsg&&!this.authService.getAccount()){
      this.loginMicrosoft();
    }else{
      this.login();
    }
    
  }

  loginMicrosoft(){
    localStorage.removeItem('inventoriesValidateDownload');
    this.bcLoadingService.show();
    this.authService.loginRedirect();
  }

  login(){
  this.loginService.loginadp().subscribe(data=>{
       if(data.codeReturn=='1'){
        this.bcLoadingService.close();
        this.permissionService.guardarToken( data.token);
        this.permissionService.saveIp(data.ip);
        this.router.navigate([this.tokenAdmin.getPermission()['home']]);
        this.logOutService.startTimer();
       }else{
         this.bcLoadingService.close();
         this.alertService.error(data.message);
       }
    });
  }

}
