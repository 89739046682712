import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'shared-capital-header',
  templateUrl: './capital-header.component.html',
  styleUrls: ['./capital-header.component.css']
})
export class CapitalHeaderComponent  {

  @Input() public isBtnBack: boolean = false;
  @Output()
  public actionBtnBackOutPut: EventEmitter<boolean> = new EventEmitter<boolean>();

 
  public actionBtnBack(): void {
    this.actionBtnBackOutPut.emit(true);
  }

}
