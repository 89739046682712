import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent {

  public enabledNotification: boolean = true;
  @Input() public title: string = "";
  @Input() public text: string;
  @Input() public link: string = "";
  @Input() public type: string;
  @Input() public enabledClose: boolean = true;
  @Input() public isDark: boolean = false;
  @Output()
  public actionLinkOutPut: EventEmitter<boolean> = new EventEmitter<boolean>();


  public disabledNotification(): void{
    this.enabledNotification = false;
  }

  public actionLink(): void {
    this.actionLinkOutPut.emit(true);
  }

}
