<div class="nl-container-header" *ngFor="let notification of listNotification"
[ngStyle]="{'background-color': isTodayOrThreeDaysAgo(notification.startDate) ? '#f5f5f5' : 'transparent'}">
  <div class="nl-left">
    <div class="nl-icon-backgroud">
      <em class="bc-icon bc-sm nl-icon-image" aria-hidden="true" role="img">calendar-times</em>
    </div>
  </div>
  <div class="nl-right">
    <div class="nl-right-head">
      <div class="nl-left-alert">
        <span>{{ notification.notificationTypes?.name }}</span>
      </div>
      <div class="nl-right-alert">
        <span class="nl-align-item">{{ notification?.startDate | date:'dd MMMM yyyy' : '-0500' }}</span>
      </div>
    </div>
    <div class="nl-right-body">
      <p class="nl-right-body-title">{{ notification.title }}</p>
      <p class="nl-right-body-description">{{ notification.description }}</p>
      <p class="nl-right-body-link">
        <a target="_blank" class="black-link" (click)="openFile(notification)">{{ notification?.textLink }}</a>
      </p>
    </div>
  </div>
</div>
