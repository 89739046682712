<div class="custom_paginator">
  <div class="rouletteContainer">
    <ul class="roulette">

      <li>
        <mat-form-field class="pageSizeSelector">
          <mat-select (selectionChange)="changePageSize()" [(ngModel)]="pageSize">
            <mat-option [value]="option" *ngFor="let option of pageSizeOptions">
              {{option}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </li>
      <li>

        Filas por p&aacute;gina {{pageSize}}

      </li>
      <li>
        <a>
          P&aacute;gina {{getCurentPage()}} de {{getTotalPages()}}
        </a>
      </li>
      <li>
        &nbsp;
      </li>
      <li>
        <a (click)="homePage()" class="homePage">
          <mat-icon>first_page</mat-icon>
        </a>
      </li>
      <li>
        <a (click)="backPage()" class="homePage">
          <mat-icon>navigate_before</mat-icon>
        </a>
      </li>
      <li>
        <a (click)="nextPage()" class="homePage">
          <mat-icon>navigate_next</mat-icon>
        </a>
      </li>
      <li>
        <a (click)="endPage()" class="homePage">
          <mat-icon>last_page</mat-icon>
        </a>
      </li>
      <li>
        <a>
          {{getTotalCurrentItems()}} registros
        </a>
      </li>
    </ul>
  </div>
</div>