import { NgModule } from '@angular/core';
import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { BcInputModule } from '@bancolombia/design-system-web/bc-input';
import { BcInputTokenModule} from '@bancolombia/design-system-web/bc-input-token';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from '../core/header/header/header.component';
import { FooterComponent } from './footer/footer.component';
import { UserMenuComponent } from './header/user-menu/user-menu.component';
import { LoginComponent } from './auth/login/login.component';
import { AdpComponent } from './auth/adp/adp.component';
import { ConfirmNewPasswordComponent } from './auth/confirm-new-password/confirm-new-password.component';
import { PasswordComponent } from './auth/password/password.component';
import { CodeComponent } from './auth/cards/code/code.component';
import { NewPasswordComponent } from './auth/new-password/new-password.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { BcButtonModule } from '@bancolombia/design-system-web/bc-button';
import { AlertChangePasswordComponent } from './auth/alert-change-password/alert-change-password.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { RouterModule } from '@angular/router';

const SDB_COMPONENTS = [
  BcInputModule,
  BcButtonModule,
  BcIconModule.forRoot({ path: 'https://library-sdb.apps.bancolombia.com/bds/6.25.3/' }),
  BcInputTokenModule
];

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    UserMenuComponent,
    LoginComponent,
    AdpComponent,
    ConfirmNewPasswordComponent,
    AlertChangePasswordComponent,
    ChangePasswordComponent,
    PasswordComponent,
    CodeComponent,
    NewPasswordComponent
  ],
  imports: [
    SDB_COMPONENTS,
    CommonModule,
    FormsModule,
    ReactiveFormsModule.withConfig({callSetDisabledState: 'whenDisabledForLegacyCode',}),
    RecaptchaModule,
    RecaptchaFormsModule,
    RouterModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    UserMenuComponent,
    LoginComponent,
    AdpComponent,
    ConfirmNewPasswordComponent,
    AlertChangePasswordComponent,
    ChangePasswordComponent,
    PasswordComponent,
    CodeComponent,
    NewPasswordComponent
  ]
})
export class CoreModule { }
