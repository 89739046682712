export class DocumentPerson {

    constructor(
        public documentCode?: string,
        public documentDescribe?: string,
        public personType?: string,
        public patternType?: string,
        public maxLength?: number) {
        
    }
}