<main>
    <div *ngIf="errorMsg" class="error-content">
        <div>
            <p> 
              <bc-icon
            size="md"
            aria-hidden="true"
            aria-label="Regresar">
            exclamation-circle
            </bc-icon>
          </div>

        <h5>Lo sentimos, tenemos problemas para iniciar su sesión.</h5>
        <div class="message">
            {{errorMsg}}
        </div>
        <div class="actions">
            <button bc-button 
            type="button"
            sizeButton="Small"
            (click)="loginMicrosoft()">INTENTAR DE NUEVO</button>
        </div>
        
    </div>
</main>