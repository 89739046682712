<main >
  <span class="text">{{textToShow}}</span>
  <span class="icon">
    <bc-icon
    size="xs"
    aria-hidden="true"
    aria-label="Descargar">
    download
  </bc-icon>
  </span>
</main>

