import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientValidateResponse } from 'src/app/shared/models/clientValidateResponse';
import { ClientResponseFormalization } from 'src/app/shared/models/formalization-clients/client-response-formalization';
import { ParamService } from 'src/app/shared/services/param/param.service';
import { environment } from 'src/environments/environment';

const LINK_PDF_MESSAGE_VINCULATION = environment.linkPdfMessageVinculation;

@Component({
  selector: 'app-client-linkup',
  templateUrl: './client-linkup.component.html',
  styleUrls: ['./client-linkup.component.css']
})
export class ClientLinkupComponent implements OnInit{

  public clientValidateResponse:ClientValidateResponse;
  clientResponseFormalization:ClientResponseFormalization = new ClientResponseFormalization();
  linkMessage: string = LINK_PDF_MESSAGE_VINCULATION;

  constructor(private activateRoute: ActivatedRoute,
    public router: Router,public paramService: ParamService){
      this.paramService.getValidarCliente().pipe().subscribe(data => {this.clientValidateResponse = data;}); 
  }

  ngOnInit(){

    this.clientResponseFormalization.nameClient= this.clientValidateResponse.firstName;
    this.clientResponseFormalization.fullnameClient= this.clientValidateResponse.fullName;
    this.clientResponseFormalization.documentType = this.clientValidateResponse.documentType;
    this.clientResponseFormalization.documentNumber = this.clientValidateResponse.documentNumber;
    this.clientResponseFormalization.activity = this.clientValidateResponse.activity;

  }

  goBackFormalization(){
    this.router.navigate(["/advisor-home-novelty-view", this.clientValidateResponse.noveltyId]);
  }

}
