<form autocomplete="off">

  <div [formGroup]="searchForm" [ngClass]="{'search': whiteBackground}">
    <bc-form-field>
      <input bc-input aria-label="input" typeInput="input-icons" type="text" placeholder="{{placeholderText}}" 
      id="buscador"  name="textSearch" formControlName="textSearch"
       (keyup)="keyUpEvent($event)" setDisabledState=true required />
      <em *ngIf="!textSearch" class="btn-search" (click)="onSearchClick()">search2</em>
      <em *ngIf="textSearch" class="btn-search" (click)="clearSearch()">remove</em>
      <span [innerHtml]="helpTextInfo"></span>

    </bc-form-field>
  </div>
  <span id="message">{{message}}</span>

</form>