<div id="modal-container" class="bc-modal-bsv" #modal>
  <div class="content" #content [ngClass]="{'full-size':isFullSize}">
    <span *ngIf="showClose" (click)="close()">
      <bc-icon size="sm" aria-hidden="true" aria-label="cerrar">
        error
      </bc-icon>
    </span>

    <div class="custom-content">

      <ng-template #customContent></ng-template>

    </div>
  </div>

</div>