import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { LogOutService } from "../../services/logOut/log-out.service";
import { Router } from "@angular/router";
import { AlertService } from "../../services/alert/alert.service";
import { BcLoadingService } from "../commons/bc-loading/bc-loading.service";
import { BcModalServiceService } from "../commons/bc-modal-service/bc-modal-service.service";
import { TokenAdmin } from "../../models/TokenAdmin";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptorService implements HttpInterceptor {
  public tokenAdmin: TokenAdmin = new TokenAdmin();

  constructor(
    public router: Router,
    public logOutService: LogOutService,
    private alertService: AlertService,
    private bcLoadingService: BcLoadingService,
    private bcModalService: BcModalServiceService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.url.includes("/closesession")) {
      this.logOutService.startTimer();
    }
    req = this.addTokenHeader(req);
    return next.handle(req).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          let jwt = evt.headers.get("X-Token") || evt.headers.get("x-token");
          if (jwt) {
            jwt = jwt.replace("Bearer", "").trimLeft();
            this.tokenAdmin.setToken(jwt);
          }
        }
      }),

      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          const jwt = err.headers.get("X-Token") || err.headers.get("x-token");
          if (jwt) {
            this.tokenAdmin.setToken(jwt);
          }
          const message =
            err.error && err.error.message ? err.error.message : err.statusText;
          this.showMessageError(err.status, message);
        }
        return of(err);
      })
    );
  }

  addTokenHeader(req: HttpRequest<any>): HttpRequest<any> {
    if (this.tokenAdmin.getToken()) {
      const currentheaders: string[] = req.headers.keys();
      const headers: any = {};
      currentheaders.forEach((key) => {
        headers[key] = req.headers.get(key);
      });

      headers["Authorization"] = `Bearer ${this.tokenAdmin.getToken()}`;

      if (req.headers.get("Content-Type") && req.headers.get("Ignore-Content-Type")) {
        headers["Content-Type"] = "application/json; charset=utf-8";
      }
      const newHeaders = new HttpHeaders(headers);
      req = req.clone({ headers: newHeaders });
    }
    return req;
  }

  showMessageError(statusError: number, statusText: string) {
    const error401: number = 401;
    const error403: number = 403;
    const error500: number = 500;
    const error400: number = 400;
    const error423: number = 423;
    let messageSend: any;

    if (statusError === error401 || statusError === error403) {
      this.logOutService.logOutBackground();
      this.bcModalService.close();
      this.bcLoadingService.close();
      this.router.navigate(["/login"]);
      messageSend = "No hemos detectado actividad los últimos 15 minutos. Se ha cerrado su sesión para proteger la información.";
      this.showAlertService(messageSend);
    } else if (statusError === error423) {
      this.logOutService.logOutBackground();
      this.bcModalService.close();
      this.bcLoadingService.close();
      this.router.navigate(["/login"]);
      messageSend = "¡Lo sentimos! No ha sido posible iniciar sesión. El Link Sala de Ventas solamente está disponible de 5:00 a.m. a 9:00 p.m.";
      this.showAlertService(messageSend);
    } else if (statusError === error500) {
      this.bcLoadingService.close();
      messageSend = "¡Lo sentimos! No ha sido posible enviar la solicitud por una falla del sistema. Por favor vuelva a intentarlo mas tarde.";
      this.showAlertService(messageSend);
    } else if (statusError === error400) {
      this.bcLoadingService.close();
      messageSend = statusText == "OK" ? "Bad Request" : statusText;
      this.showAlertService(messageSend);
    } else {
      this.bcLoadingService.close();
      this.showAlertService(statusText);
    }
  }

  showAlertService(message: any){
    this.alertService.error(message);
  }
}
