import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { ClientValidateResponse } from '../../models/clientValidateResponse';
import { ModuleResponse } from '../../models/ModuleResponse';


@Injectable({
  providedIn: 'root'
})

export class ParamService {
  private clientValidateResponse: ClientValidateResponse;
  private emailAddr: string;
  private password: string;
  private codeSecurity: string;
  private permission: ModuleResponse[];
  private isSendEmail: boolean;

//metodos de respuestas
  public getValidarCliente(): Observable<ClientValidateResponse>  {
    return observableOf( this.clientValidateResponse );
  }

  public  setValidarCliente(clientValidateResponse: ClientValidateResponse) {
    this.clientValidateResponse = clientValidateResponse;
  }

  //metodo para el intercambio de validacion si envio correo
  public getIsSendEmail(): boolean {
    return (this.isSendEmail);
  }

  public setIsSendEmail(sendEmail: boolean) {
    this.isSendEmail = sendEmail;
  }

  //metodo para intercambio de email
  public getEmail(): string {
    return (this.emailAddr);
  }

  public  setEmail(emailAddr: string) {
  this.emailAddr = emailAddr;
  }

  //metodo para intercambio de password 
  public getPassword(): string {
    return (this.password);
  }

  public  setPassword(password :string) {
  this.password= password;
  }

  //metodo para intercambio de codigo de seguridad
  public getCodeSecurity(): string {
    return (this.codeSecurity);
  }

  public  setCodeSecurity(codeSecurity: string) {
  this.codeSecurity= codeSecurity;
  }

  //Metodo para guardar roles y rutas
  public setPermission(Permission: ModuleResponse[] ) {
  this.permission = Permission;
  }

  public getPermission(){
    return (this.permission);
  }

}
