import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';
import { TokenAdmin } from '../../models/TokenAdmin';
import { CustomerRequestDocuments } from '../../models/customerRequestDocuments';

@Injectable({
  providedIn: 'root'
})
export class HighRiskDocumentsService {

  private urlEndPoint = URL_SERVICIOS;
  private service = 'documents-required-for-risky-customer';
  private httpHeaders: any;
  private token: string;
  private tokenAdmin: TokenAdmin = new TokenAdmin();


  constructor(private http: HttpClient) { }

  listHighRiskDocuments(documentType:string, documentNumber:string) {
    this.token = this.tokenAdmin.getToken();
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.token
    });
    return this.http.get<CustomerRequestDocuments>
      (`${this.urlEndPoint}/${this.service}/${documentType}/${documentNumber}`, { headers: this.httpHeaders });

  }
}

