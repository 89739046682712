import { Component } from '@angular/core';
import { BcModalServiceService } from 'src/app/shared/services/commons/bc-modal-service/bc-modal-service.service';

@Component({
  selector: 'app-alert-change-password',
  templateUrl: './alert-change-password.component.html',
  styleUrls: ['./alert-change-password.component.css']
})
export class AlertChangePasswordComponent  {

  private callBack: Function;
  public isMessageMigration: boolean = true;

  constructor(private bcModalServiceService: BcModalServiceService) { }


  public set data(data: any) {
    if (data) {
      if (data.callBack) {
        this.callBack = data.callBack;
      }

      if (data.isMessageMigration !== undefined && data.isMessageMigration === false) {
        this.isMessageMigration = data.isMessageMigration;
      } else {
        this.isMessageMigration = true;
      }
    }
  }

  public changePassword(): void {
    if (this.callBack) {
      this.callBack();
    }
    this.bcModalServiceService.close();
  }
}
