import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/config';
import { TokenAdmin } from '../../models/TokenAdmin';

@Injectable({
  providedIn: 'root'
})
export class DownloadLogService {
  private urlEndPoint = URL_SERVICIOS;
  private service = 'download/logfunctional.xlsx';
  private httpHeaders: any;
  private token: string;
  private tokenAdmin: TokenAdmin = new TokenAdmin();

  constructor(private http: HttpClient) { }

  getLog(start:string,end:string) {
    this.token = this.tokenAdmin.getToken();

    const httpOptions = {
    responseType: 'blob' as 'json',
    headers: new HttpHeaders({
      Authorization: 'Bearer ' + this.token
    })
  };

    return this.http.post(`${this.urlEndPoint}/${this.service}`,{start,end}, httpOptions);
  }
}
