import { Injectable } from '@angular/core';
import { URL_SERVICIOS,ambiente } from 'src/app/config/config';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { TokenAdmin } from '../../models/TokenAdmin';
import { Login } from '../../models/login';
import { ResponseService } from '../../models/response-service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  
  private urlEndPoint = URL_SERVICIOS;
  private ambiente = ambiente;
  private service = null;
  private httpHeaders = null;
  private token: string;
  private tokenAdmin: TokenAdmin = new TokenAdmin();

  constructor( private http: HttpClient ) { }

  login(login: Login) {
    this.service = 'login';
    this.httpHeaders = new HttpHeaders({'Content-Type' : 'application/json'});
    return this.http.post<ResponseService>(`${this.urlEndPoint}/${this.service}`, login , {headers: this.httpHeaders});
  }

  loginValidar(login: Login) {
    this.service = 'login/validar';
    this.token = this.tokenAdmin.getToken();
    this.httpHeaders = new HttpHeaders({'Content-Type' : 'application/json',
    Authorization: 'Bearer ' + this.token });

    return this.http.post<ResponseService>(`${this.urlEndPoint}/${this.service}`, login , {headers: this.httpHeaders});
  }

  loginadp() {
    this.service = 'login-adp';
    this.httpHeaders = new HttpHeaders({'Content-Type' : 'application/json',
    'x-access-token-auth':sessionStorage.getItem('msal.idtoken')
  });
    return this.http.post<any>(`${this.urlEndPoint}/${this.service}`, {} , {headers: this.httpHeaders});
  }


}
