import { Routes, RouterModule } from "@angular/router";
import { AdpComponent } from "./core/auth/adp/adp.component";
import { CodeComponent } from "./core/auth/cards/code/code.component";
import { ConfirmNewPasswordComponent } from "./core/auth/confirm-new-password/confirm-new-password.component";
import { LoginComponent } from "./core/auth/login/login.component";
import { NewPasswordComponent } from "./core/auth/new-password/new-password.component";
import { PasswordComponent } from "./core/auth/password/password.component";
import { LogComponent } from "./modules/backoffice/components/log/log.component";
import { CanActivatePortalService } from "./shared/services/guard/can-activate-portal.service";
import { ClientLinkupComponent } from "./modules/advisor/components/novelties/request-formalize/responses/client-linkup/client-linkup.component";
import { BlockedClientComponent } from "./modules/advisor/components/novelties/request-formalize/responses/blocked-client/blocked-client.component";
import { UpdatedClientComponent } from "./modules/advisor/components/novelties/request-formalize/responses/updated-client/updated-client.component";

export const routes: Routes = [
  { path: "", component: LoginComponent },
  { path: "admin", component: AdpComponent },
  { path: "login", component: LoginComponent },
  { path: "confirmnewpassword", component: ConfirmNewPasswordComponent },
  { path: "password", component: PasswordComponent },
  { path: "forgotPasswordCode", component: CodeComponent },
  { path: "newPassword", component: NewPasswordComponent },
  {
    path: "log",
    component: LogComponent,
    canActivate: [CanActivatePortalService],
  },
  {
    path: "advisor-home",
    loadChildren: () => import("./modules/advisor/modules/advisor-home/advisor-home.module")
      .then(m => m.AdvisorHomeModule), canActivate: [CanActivatePortalService]
  },
  {
    path: "advisor-home-tabs",
    loadChildren: () => import("./modules/advisor/modules/advisor-home-tabs/advisor-home-tabs.module")
      .then(m => m.AdvisorHomeTabsModule)
  },
  {
    path: "advisor-home-request-view",
    loadChildren: () => import("./modules/advisor/modules/requests/advisor-request-view/advisor-request-view.module")
      .then(m => m.AdvisorRequestViewModule), canActivate: [CanActivatePortalService]

  },
  {
    path: "advisor-home-novelty-view",
    loadChildren: () => import("./modules/advisor/modules/novelties/advisor-novelty-view/advisor-novelty-view.module")
      .then(m => m.AdvisorNoveltyViewModule)
  },
  {
    path: "request-home",
    loadChildren: () => import("./modules/backoffice/backoffice.module").then(m => m.BackofficeModule),
    canActivate: [CanActivatePortalService],
  },
  {
    path: "request-home-novelty-view",
    loadChildren: () => import("./modules/backoffice/modules/backoffice-novelty-view/backoffice-novelty-view.module")
      .then(m => m.BackofficeNoveltyViewModule)
  },
  {
    path: "request-home-request-view",
    loadChildren: () => import("./modules/backoffice/modules/backoffice-request-view/backoffice-request-view.module")
      .then(m => m.BackofficeRequestViewModule)
  },
  {
    path: "clientvalidate",
    loadChildren: () => import("./modules/consult-users/modules/client-validate/client-validate.module")
      .then(m => m.ConsultUsersModule),
    canActivate: [CanActivatePortalService]
  },
  {
    path: "errorresponse",
    loadChildren: () => import("./modules/consult-users/modules/responses/error-response/error-response.module")
      .then(m => m.ErrorResponseModule)
  },
  {
    path: "failedresponse/:typeBlocking",
    loadChildren: () => import("./modules/consult-users/modules/responses/failed-response/failed-response.module")
      .then(m => m.FailedResponseModule)
  },
  {
    path: "high-risk-documents",
    loadChildren: () =>
      import("./modules/consult-users/modules/responses/high-risk-documents/high-risk-documents.module")
        .then(m => m.HighRiskDocumentsModule)
  },
  {
    path: "lockupgraderesponse",
    loadChildren: () => import("./modules/consult-users/modules/responses/lock-upgrade/lock-upgrade.module")
      .then(m => m.LockUpgradeModule)
  },
  {
    path: "successfulresponse",
    loadChildren: () =>
      import("./modules/consult-users/modules/responses/successful-response/successful-response.module")
        .then(m => m.SuccessfulResponseModule)
  },
  {
    path: "adminhome",
    loadChildren: () => import("./modules/administrator/administrator.module")
      .then(m => m.AdministratorModule),
    canActivate: [CanActivatePortalService],
  },
  {
    path: "inventory",
    loadChildren: () => import("./modules/inventory/inventory.module")
      .then(m => m.InventoryModule),
    canActivate: [CanActivatePortalService]
  },
  { path: "linkup", component: ClientLinkupComponent },

  { path: "blockedclient/:typeBlocking", component: BlockedClientComponent },

  { path: "updatedclient/:typeBlocking", component: UpdatedClientComponent },
  {
    path: "notifications-admin",
    loadChildren: () => import("./modules/administrator/components/notifications/notifications.module")
      .then(m => m.NotificationsModule),
      canActivate: [CanActivatePortalService]
  },
  {
    path: "advisor-publication",
    loadChildren: () => import("./modules/advisor/modules/news-publication/news-publication.module")
      .then(m => m.NewsPublicationModule)
  },
  { path: "**", component: LoginComponent },
];

export const routing = RouterModule.forRoot(routes, {});
