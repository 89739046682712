//Externals
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { routing } from './app.routing';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RecaptchaFormsModule } from 'ng-recaptcha';
import { environment } from '../environments/environment';

//Components
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatChipsModule } from '@angular/material/chips';
import { getSpanishPaginatorIntl } from './config/es-paginator-intl';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { BcInputModule } from '@bancolombia/design-system-web/bc-input';
import { BcButtonModule } from '@bancolombia/design-system-web/bc-button';
import { BcAlertModule } from '@bancolombia/design-system-web/bc-alert';
import { BcRadioModule } from '@bancolombia/design-system-web/bc-radio';
import { BcTabsModule } from '@bancolombia/design-system-web/bc-tabs-group';
import { BcCheckboxModule } from '@bancolombia/design-system-web/bc-checkbox';
import { BcModalModule } from '@bancolombia/design-system-web/bc-modal';
import {BcCarouselModule} from '@bancolombia/design-system-web/bc-carousel';

import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(es);

import { MsalService, MsalModule } from '@azure/msal-angular';

import { BcDialogService, BcServicesModule } from '@bancolombia/design-system-web/bc-services';
import { AppComponent } from './app.component';
import { HomeComponent } from './modules/base/home/components/home/home.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { AuthInterceptorService } from './shared/services/auth-interceptor/auth-interceptor.service';
import { CanActivatePortalService } from './shared/services/guard/can-activate-portal.service';

const SDB_COMPONENTS = [
  BcInputModule,
  BcButtonModule,
  BcAlertModule,
  BcRadioModule,
  BcTabsModule,
  BcCheckboxModule,
  BcModalModule,
  BcCarouselModule,
  BcServicesModule,
  BcIconModule.forRoot({ path: 'https://library-sdb.apps.bancolombia.com/bds/6.25.3/' }),
];

const MATERIAL_COMPONENTS = [
  MatTableModule,
  MatSortModule,
  MatChipsModule,
  MatDialogModule,
  MatPaginatorModule,
  MatInputModule,
  MatTabsModule,
  MatFormFieldModule,
  MatSelectModule,
  MatOptionModule,
  MatIconModule,
  MatMenuModule,
  MatTooltipModule,
  MatRadioModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatNativeDateModule,
];

@NgModule({

  declarations: [
    AppComponent,
    HomeComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    ReactiveFormsModule.withConfig({callSetDisabledState: 'whenDisabledForLegacyCode',}),
    HttpClientModule,
    routing,
    RecaptchaModule,
    RecaptchaFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    CoreModule,
    MATERIAL_COMPONENTS,
    /*imports bancolombia design */
    SDB_COMPONENTS,
    /*config msal adp */
    MsalModule.forRoot({
      auth: {
        clientId: environment.clientId,
        redirectUri: environment.redirectUri,
        authority: environment.authority,
        postLogoutRedirectUri: environment.redirectUriLogout
      }
    }
    )

  ],
  providers: [
    { provide: LOCALE_ID, useValue: "es-ES" },
    { provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl() },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.siteKey,
      } as RecaptchaSettings,
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }, CanActivatePortalService,
    MsalService,
    BcDialogService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
