import { Component, OnInit } from '@angular/core';
import { PermissionService } from '../../services/permission/permission.service';

@Component({
  selector: 'shared-capital-footer',
  templateUrl: './capital-footer.component.html',
  styleUrls: ['./capital-footer.component.css']
})
export class CapitalFooterComponent implements OnInit {

  ip: string = "";
  todayDate : Date = new Date();

  constructor(private permissionService: PermissionService,) { }

  ngOnInit(): void {
    const isIp = sessionStorage.getItem("ip");
    this.ip = isIp ? isIp : this.permissionService.getIp();
  }

}
