import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuxiliaryBackofficeResponse } from '../../models/auxiliary-backoffice-response';
import { HeadRelationshipResponse } from '../../models/headRelationshipResponse';

@Component({
  selector: 'app-filters-backoffice',
  templateUrl: './filters-backoffice.component.html',
  styleUrls: ['./filters-backoffice.component.css']
})
export class FiltersBackofficeComponent implements OnInit {

  @Input() _currentTab;

  // output
  @Output() _filterText: EventEmitter<string> = new EventEmitter();

  @Input() canFilterbyHeadRelationship: boolean = false;
  @Input() canSeeBackofficeTab: boolean = false;

  @Input() filteredheadRelationship: Observable<HeadRelationshipResponse[]>;
  @Input() filteredAuxiliaryBackoffice: Observable<AuxiliaryBackofficeResponse[]>;

  headRelationshipCtrl = new UntypedFormControl();
  auxiliaryBackofficeCtrl = new UntypedFormControl();

  @Output() onFormGroupHeadRelationshipChange = new EventEmitter<any>();
  @Output() onFormGroupAuxiliaryBackofficeChange = new EventEmitter<any>();
  

  constructor() {
    //has no implementation
   }

  ngOnInit(): void {
    this.onFormGroupHeadRelationshipChange.emit(this.headRelationshipCtrl);
    this.onFormGroupAuxiliaryBackofficeChange.emit(this.auxiliaryBackofficeCtrl);
  }

  get searchMessages(): string {
    const searchMessages = [
      "Busca por número de documento, por código de negocio o fecha DDMMYYYY",
      "Busca por código o nombre de negocio"
    ];
    return searchMessages[this._currentTab]
  }

  filterText(textFilter: string) {
    this._filterText.emit(textFilter);
  }

  emitHeadrelationshipCtrl() {
    this.onFormGroupHeadRelationshipChange.emit(this.headRelationshipCtrl);
  }

  emitBackofficeCtrl() {
    this.onFormGroupAuxiliaryBackofficeChange.emit(this.auxiliaryBackofficeCtrl);
  }

}
