<main>
  <div>
    <p>
      <bc-icon class="icon-backgroud" size="md" 
      name="alert" height="30px" aria-hidden="true" 
      aria-label="prueba"></bc-icon>
  </div>

  <h2>Actualizar sesión</h2>
  <div>
    <p>No se ha detectado actividad en los
      ultimos minutos, por su seguridad su
      sesión se ha cerrado. </p>
  </div>
  <button type="button" bc-button (click)="cerrar()">ACTUALIZAR</button>
</main>