import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { ParamService } from 'src/app/shared/services/param/param.service';

@Component({
  selector: 'app-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.css']
})
export class CodeComponent implements OnInit {
  codigoForm: UntypedFormGroup;

  email: string;
  submitted = false;
  loading = false;
  public validToken: boolean = false;


  constructor(
    private formBuilder: UntypedFormBuilder,
    public router: Router,
    public alertService: AlertService,
    private paramService: ParamService,
    private location: Location) { }

  ngOnInit() {
    this.email = this.paramService.getEmail();
    this.codigoForm = this.formBuilder.group({
      SecurityCode: ['', Validators.required],
    });

  }

  get f() { return this.codigoForm.controls; }

  validateCode() {
    this.submitted = true;
    this.loading = true;
    const codigo = this.codigoForm.get('SecurityCode').value;

    // stop here if form is invalid
    if (this.codigoForm.invalid) {
      return;
    } else {
      if (this.email) {
        this.paramService.setCodeSecurity(codigo);
        this.router.navigate(['/newPassword']);
        this.alertService.error("La nueva contraseña debe ser de mínimo 10 caracteres y "
          + "contar con 3 de los siguientes tipos de caracteres: una letra "
          + "minúscula, una letra mayúscula, un dígito o un carácter especial");
      } else {
        this.submitted = false;
        this.loading = false;
        this.alertService.error('El código de seguridad y el correo electrónico son requeridos.');
      }
    }
  }

  goBack() {
    this.location.back()
  }

  public onValidToken(e:boolean): void {
    this.validToken = e;
  }

  public changeValue(e:string): void {
    this.codigoForm.get('SecurityCode').setValue(e);
  }

}
