import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { AuxiliaryBackofficeResponse } from "../../models/auxiliary-backoffice-response";
import { HeadRelationshipResponse } from "../../models/headRelationshipResponse";

@Component({
  selector: "shared-section-title",
  templateUrl: "./section-title.component.html",
  styleUrls: ["./section-title.component.css"],
})
export class SectionTitleComponent {

  @Input()
  public title: string = "";

  @Input()
  public subTitle: string = "";

  @Input()
  public backRouterLink: any = [];

  @Input()
  public isButton: boolean = false;

  @Input()
  public typeButton: string;

  @Input()
  public titleButton: string;

  @Input()
  public disabledButton: boolean = false;

  @Input()
  public isBtnBack: boolean = true;

  @Input()
  public isBackofficeOrHeadRelationship: boolean = false;

  @Input()
  public isFilterByText: boolean = false;

  @Output()
  public actionButtonOutPut: EventEmitter<boolean> = new EventEmitter<boolean>();

  // Filters
  @Input() _currentTab: string;

  @Output() _filterText: EventEmitter<string> = new EventEmitter();

  @Input() canSeeBackofficeTab: boolean;
  @Input() canFilterbyHeadRelationship: boolean;

  @Input() filteredheadRelationship: Observable<HeadRelationshipResponse[]>;
  @Input() filteredAuxiliaryBackoffice: Observable<AuxiliaryBackofficeResponse[]>;

  headRelationshipCtrl = new UntypedFormControl();
  auxiliaryBackofficeCtrl = new UntypedFormControl();

  @Output() onFormGroupHeadRelationshipChange = new EventEmitter<any>();
  @Output() onFormGroupAuxiliaryBackofficeChange = new EventEmitter<any>();


  public hasSubtitle(): boolean {
    return this.subTitle && this.subTitle.trim() != "";
  }

  public actionButton(): void {
    this.actionButtonOutPut.emit(true);
  }

  filterText(text: string) {
    this._filterText.emit(text);
  }

  public _onFormGroupHeadRelationshipChange(_event) {
    this.headRelationshipCtrl = _event;
    this.onFormGroupHeadRelationshipChange.emit(this.headRelationshipCtrl);
  }

  public _onFormGroupAuxiliaryBackofficeChange(_event) {
    this.auxiliaryBackofficeCtrl = _event;
    this.onFormGroupAuxiliaryBackofficeChange.emit(this.auxiliaryBackofficeCtrl);
  }



}
