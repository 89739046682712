import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

const MIN_LEN = 3;

@Component({
  selector: 'app-bc-search-input2',
  templateUrl: './bc-search-input2.component.html',
  styleUrls: ['./bc-search-input2.component.css']
})
export class BcSearchInput2Component implements OnInit {

  emitData: boolean = false;
  searchForm: UntypedFormGroup;

  @Input() helpText: string;
  @Output() filter: EventEmitter<string> = new EventEmitter<string>();
  @Input() realTime: boolean = false;
  @Input() outerline: string = 'false';
  @Input() message: string = "";
  @Input() whiteBackground: boolean = false;
  @Input() placeholderText: string = 'Buscar';
  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      textSearch: ["", Validators.required],
    });

    this.searchForm.controls.textSearch.setValue(sessionStorage.getItem('searchTextFilter') !== 'undefined'
      && sessionStorage.getItem('searchTextFilter') ?
      JSON.parse(sessionStorage.getItem('searchTextFilter')) : "");
  }

  keyUpEvent(event: any) {
    const enterKeyCode = 13;
    if (this.textSearchIsValid() &&
      // (this.realTime || event['keyCode'] == enterKeyCode)) {
      (event['keyCode'] == enterKeyCode)) {
      this.filter.emit(this.searchForm.controls.textSearch.value);
    }

  }

  onSearchClick() {
    if (this.textSearchIsValid()) {
      this.filter.emit(this.searchForm.controls.textSearch.value);
    }
  }

  textSearchIsValid() {
    return (this.searchForm.controls.textSearch != undefined &&
      (this.searchForm.controls.textSearch.value.length == 0 ||
        this.searchForm.controls.textSearch.value.length >= MIN_LEN));
  }

  clearSearch() {
    this.searchForm.controls.textSearch.setValue('');
    this.filter.emit(this.searchForm.controls.textSearch.value);
  }

  get textSearch(): string {
    return this.searchForm.controls.textSearch.value;
  }

  get helpTextInfo(): string {
    if (this.searchForm && this.searchForm.controls.textSearch.value) {
      return (this.searchForm.controls.textSearch.value !== ''
        && this.searchForm.controls.textSearch.value.length >= MIN_LEN)
        ? this.helpText
        : 'Escribe al menos tres caracteres para iniciar la busqueda!';
    }
  }
}
