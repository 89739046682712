import { CommonFunctions } from "../commonFunctions";
import { DocumentPerson } from "../documentPerson";

export class ClientResponseFormalization{
  nameClient:string;
  documentType:string;
  documentNumber:string;
  commonFunctions: CommonFunctions;
  documentPerson: DocumentPerson;
  activity:string;
  fullnameClient?:string;
  isHighlyRiskCustomer?:boolean;
}